.news-container {
  &.archive {
    .section-title-upper {
      padding: 10px 0;
    }
  }
}

.news-block {
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3 - 15px));
  grid-gap: 20px;
  margin-top: 20px;
  position: relative;
}

.news-item {
  cursor: pointer;
  &:hover {
    .news-item--title {
      color: $primary-color;
    }
  }
  &--rentRoom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  &--wheelechair {
    background: center/cover no-repeat;
    width: 100%;
    position: relative;
  }
  &--picture {
    background: center/cover no-repeat;
    width: 100%;
    height: 200px;
    position: relative;
    @include respond-to("s") {
      min-height: 209px;
    }
    .news-item--data {
      background-color: var(--superColorBg);
      font-weight: 600;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 10px 14px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: var(--superColorText);

      svg {
        margin-right: 5px;
        path {
          fill: var(--superColorText);
        }
      }
    }
  }

  &--title {
    padding: 10px 0 24px 0;
    line-height: 132.5%;
    font-feature-settings: "pnum" on, "lnum" on;
    letter-spacing: 0.01em;
    transition: $transition-base;
    @include respond-to("m") {
      padding: 15px 0 10px 0;
    }
  }

  &--content {
    line-height: 137.5%;
    color: var(--superColorText);
  }
  &__read-more {
    display: none;
    @include respond-to("m") {
      display: block;
      text-decoration: none;
      color: var(--primaryColor2);
      font-size: 1.2rem;
      line-height: 14px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 24px;
    }
  }
}

.news-pagination {
  margin-top: 40px;
  color: var(--superColorText);
  @include respond-to("m") {
    display: none;
  }
  .pagination-side {
    padding: 0 20px;
    cursor: pointer;
    width: 16px;
    span {
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.news-action {
  display: none;
  @include respond-to("m") {
    display: block;
    margin-top: 36px;
  }
}
