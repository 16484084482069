/* raleway-300 - latin */
@font-face {
  font-family: Raleway;
  font-weight: 300;
  src: url(../assets/font/Raleway-Light.ttf)
}
/* raleway-regular - latin */
@font-face {
  font-family: Raleway;
  font-weight: 400;
  src: url(../assets/font/Raleway-Regular.ttf)
}
/* raleway-500 - latin */
@font-face {
  font-family: Raleway;
  font-weight: 500;
  src: url(../assets/font/Raleway-Medium.ttf)
}
/* raleway-600 - latin */
@font-face {
  font-family: Raleway;
  font-weight: 600;
  src: url(../assets/font/Raleway-SemiBold.ttf)
}

body{
  font-family: 'Raleway';
  background-color: var(--superColorBg);
}