.gallery-section{
  margin: 20px 0;
  @include respond-to('s') {
    margin: 25px 0;
  }

  &__wrapper {
    @include respond-to('s') {
      background-color: var(--primaryColorLight);
    }
  }
  .section-title-upper{
    margin-bottom: 20px;
    @include respond-to('m') {
      text-align: left;
    }
    @include respond-to('s') {
      margin-bottom: 40px;
    }
  }

  .swipe-container{
    padding: 0 80px;
    background-color: var(--primaryColorLight);
    position: relative;
    border: 1px solid var(--primaryColorLight2);
    @include respond-to('s') {
      padding: 0;
    }
    @include respond-to('xs') {
      padding: 0 50px;
    }

    a{
      text-decoration: none;
      color: var(--superColorText);

      &:hover{
        color: $primary-color;
        transition: $transition-base;
      }
    }
  }

  .swiper-container{
    height: 80px;
    @include respond-to('s') {
      height: 51px;
    }
    .swiper-slide{
      cursor: pointer;
      @include respond-to('m') {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.is-active{
      font-weight: 600;
    }

      .xs-span{
        color: var(--superColorText);
        transition: $transition-base;
        &:hover {
          color: $primary-color;
        }
      }
    }

    .swiper-pagination{
      opacity: 0;
    }
  }

  .double-left{
    position: relative;
    cursor: pointer;
    width: 20px;
    @include respond-to('s') {
      display: none;
    }
    svg path {
      transition: $transition-base;
    }
    &:hover {
      svg path {
        fill: $primary-color;
      }
    }
    svg:last-child{
      position: absolute;
      top: 0;
      left: 5px;
    }

  }

  .arrow-left, .arrow-right{
    position: absolute;
    top: 40%;
  }

  .arrow-left{
    left: 20px;
    @include respond-to('s') {
      left: 0;
    }
  }

  .arrow-right{
    right: 20px;
    transform: rotate(180deg);
    @include respond-to('s') {
      right: 0;
    }
  }

  .single-arrow-left, .single-arrow-right{
    margin-left: 20px;
    cursor: pointer;
    @include respond-to('s') {
      margin-left: 0;
    }
    &:hover {
      svg path {
        fill: $primary-color;
      }
    }
    svg{
      path {
        transition: $transition-base;
        fill: var(--superColorText);
        stroke: var(--primaryColorLight);
      }
    }
  }

}

.gallery-list{
  display: grid;
  grid-template-columns: repeat(4, calc(100%/4 - 15px));
  grid-gap: 20px;
  margin-top: 20px;
  position: relative;
  @include respond-to('m') {
    grid-template-columns: repeat(3,calc(100%/3 - 15px));
  }
  @include respond-to('s') {
    grid-template-columns: repeat(2,calc(100%/2 - 15px));
    margin-top: 25px;
  }
  @include respond-to('xs') {
    grid-template-columns: 100%;
  }
  .news-item--picture {
    @include respond-to('s') {
      min-height: 200px;
    }
    .news-item--data {
      @include respond-to('s') {
        padding: 14px 41px;
      }
    }
  }
}

.gallery-item{
  width: 298px;
  height: 175px;

  img{
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}
// Change style in subpage gallery show item
.gallery-show {
  .left-container {
    @include respond-to('m') {
      display: none;
    }
  }
  .right-container {
    @include respond-to('m') {
      width: 100%;
      margin: 20px 0;
    }
    @include respond-to('s') {
      margin-top: 15px;
    }
  }
  .section-gallery--swiper {
    @include respond-to('s') {
      display: none;
    }
  }
  &__mobile-lightbox {
    display: none;
    @include respond-to('s') {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }
    &__wrapper {
      @include respond-to('s') {
        width: 100%;
      }
    }
  }
  .news-container.show {
    .gallery-show__mobile-lightbox__pictures {
      @include respond-to('s') {
        animation: fadeIn .4s;
        display: grid;
        grid-template-columns: repeat(2,calc(100% / 2 - 5px));
        grid-gap: 8px;
      }
      img {
        @include respond-to('s') {
          width: 100%;
          height: 200px;
          object-fit: cover;
          float: none;
          margin: 0;
        }
        @include respond-to('xs') {
          height: 101px;
        }
      }
    }
    .section-title-upper {
      @include respond-to('s') {
        margin: 21px 0 36px 0;
        text-align: left;
      }
    }
  }
  .news-show--header {
    .xs-span {
      @include respond-to('s') {
        font-size: 1.3rem;
      }
    }
  }
}