// If you have any variables in your code, place it here


//Colors

$primary-color: #D2AB67;
$primary-light-color: #EFEBE8;

$gray-light: #AEAEAE;
$gray-dark: #494949;
$gray-norm: #EFEBE8;

$white-color: #fff;
$text-color: #222222;

$dark-line: #515151;
$light-line: #ADADAD;

$error-color: #FF2727;

//change global color
$yellow-color: #FFFF00;
$black-color: #FFFFFF;

// buttons
$light-btn: #D2AC67;

// Box shadows
$small-box-shadow : 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
$medium-box-shadow : 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

//background with opacity
$primary-opacity: rgba(210, 171, 103, 0.96);

// transition base
$transition-base: all 0.3s ease-in-out;

//// BREAKPOINTS
//$xlarge: 1500px;
//$xmedium: 1440px;
//$medium: 1024px;
//$ipad: 768px;
//$mobile: 767px;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
        'xs': 576px,
        's': 768px,
        'm': 992px,
        'l': 1200px,
        'xl': 1315px,
) !default;

