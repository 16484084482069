.primary-scrollbar{
  &::-webkit-scrollbar{
    border-radius: 10px;
    width: 6px;
    height: 6px;
    background-color: rgb(230, 231, 232);
    background-color:green;
  }
  //sfc

  &::-webkit-scrollbar-track{

  }

  &::-webkit-scrollbar-thumb{
    //background-color:$primary-color;
    border-radius: 10px;
    width: 8px;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
    }
    &::-webkit-scrollbar{
      // background-color: rgb(230, 231, 232);
      background-color: transparent;
    }
  }
}