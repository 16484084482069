.section-staff--info{
  padding-bottom: 30px;

  .staff-block--name{
    padding-bottom: 20px;
    border-bottom: 1px solid var(--primaryColor);
    margin-bottom: 35px;

    h1, h2{
      color: var(--superColorText);
    }

    .staff-name{
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 132.5%;
      letter-spacing: 0.01em;
      margin-bottom: 10px;
    }
  }

  p, div{
    line-height: 156.5%;
    color: var(--superColorText);
  }
  p {
    img {
      max-width: 100%;
      @include respond-to('s') {
        margin: 0 auto;
        max-width: 100%;
        width: 100% !important;
      }
    }
  }

  table {
    width: 100% !important;
    @include respond-to('xs') {
      display: table-cell;
    }
  }

  tr {
    display: flex;
    @include respond-to('xs') {
      flex-direction: column;
    }

    td {
      width: 100% !important;
      @include respond-to('s') {
        width: 50% !important;
      }
      @include respond-to('xs') {
        width: 100% !important;
        & + td {
          @include respond-to('s') {
            margin-top: 20px;
          }
        }
      }
    }
  }
  em {
    font-style: italic;
  }
  blockquote, q {
    font-style: italic;
  }
  .staticPages {
    .xs-span  {
      p, span, strong {
        color: var(--superColorText) !important;
        font-family: 'Raleway', sans-serif !important;
      }
    }
  }
  em {
    line-height: 28px!important;
    @include respond-to('s') {
      line-height: 22px !important;
    }
    span {
      font-size: 1.8rem !important;
      @include respond-to('s') {
        font-size: 1.25rem !important;
      }
    }
  }
}