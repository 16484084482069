//Overwriting styles according to class on a particular subpage staticPage and other basic styles for elements

//sponsoring pages
.page-185, .page-186, .page-187, .page-188 {
  table tr{
    align-items: center;
  }
}
.page-373 {
  table  {
    tr {
      @include respond-to('s') {
        flex-direction: column;
      }
      td {
        & + td {
          margin-left: 20px;
          @include respond-to('s') {
            margin-left: 0;
            margin-top: 20px;
          }
        }
        ul {
          li {
            font-weight: 400;
            a {
              font-size: 1.3rem;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

//overwriting styles on other pages where the editor overwrites inline
[container-type="cycles"] {
  .section-staff--info {
    .xs-span {
      p, span {
        color: var(--superColorText) !important;
      }
    }
  }
}

[container-type="attemptPlan"] {
  .section-title-upper{
    margin-bottom: 20px;
  }
  .block-item {
    .section-title {
      transition: $transition-base;
    }
    &:hover {
      .section-title {
        color: $primary-color;
      }
    }
  }

}

//Styles to archive-link
.archive-link {
  margin: 40px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    transition: ease-in-out 0.3s;
    text-align: center;
    &:hover {
      color: var(--superGray);
    }
  }
}

.lightbox {

  .lb-prev{
    background: url(../assets/images/lightbox/prev.png) left 48% no-repeat !important;
  }

  .lb-next{
    background: url(../assets/images/lightbox/next.png) right 48% no-repeat !important;
  }

  .lb-close{
    background: url(../assets/images/lightbox/close.png) top right no-repeat !important;
  }
}