.block-wrapper{
  padding-bottom: 20px;
}

.block-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 137.5%;
  //margin-bottom: 20px;
  color: var(--superColorText);

  >div{
    font-feature-settings: 'pnum' on, 'lnum' on;
    max-width: 66%;
    @include respond-to('m') {
      max-width: 100%;
    }
  }

  .block-data{
    white-space: nowrap;
  }
}

.block-item{
  border-bottom: 1px solid var(--primaryColor);

  &:hover{
    cursor: pointer;

    .section-title{
      color: var(--superColorText);
    }
  }
  &.image{
    height: 119px;
    margin: 10px 0;
  }

  .section-title{
    margin-bottom: 20px;
  }

  img{
    float: left;
    width: 178px;
    height: 90%;
    object-fit: cover;
    padding-right: 16px;
  }

}

.primary-pagination{
  display: flex;
  justify-content: center;
  margin: 40px 0;
  flex-wrap: wrap;

  >div{
    margin-right: 5px;
  }

  .pagination-dots{
    color: var(--superGray);
    display: flex;
    align-items: flex-end;
    font-size: 25px;
  }
}