.contactContainer {
  margin-top: 10px;
  //border-bottom: 1px solid var(--primaryColor);
  padding: 20px 0;
  &__row {
    flex-wrap: wrap;
    margin-top: 0;
    border: 0;
    .contactItem {
      padding: 25px 0 15px 0;
      border-top: 1px solid var(--primaryColor);
    }
  }
  @include respond-to("s") {
    margin-top: 4px;
  }
  .to-flex-cl {
    @include respond-to("xl") {
      //flex: 1;
    }
  }
  a {
    color: var(--superColorText);
    text-decoration: none; /* no underline */
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 24px;
    & > *:first-child {
      margin-top: 1.8rem;
    }
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
    @include respond-to("s") {
      font-size: 1.3rem;
      line-height: 22px;
      margin-bottom: 29px;
    }
  }

  a:hover {
    color: $primary-color;
  }

  .contactTitle {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 132.5%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-bottom: 15px;
    @include respond-to("s") {
      font-size: 1.4rem;
      margin-bottom: 27px;
    }
  }

  .contactBold {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 132.5%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    @include respond-to("s") {
      font-size: 1.3rem;
    }
  }

  .contactText {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 156.5%;
    margin-bottom: 17px;
    & > *:first-child {
      margin-top: 1.8rem;
    }
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
    @include respond-to("s") {
      font-size: 1.3rem;
      line-height: 22px;
      margin-bottom: 29px;
    }
  }

  .contactTextLast {
    margin-bottom: 80px;
  }

  .contactMap {
    width: 100%;
    height: 600px;
    @include respond-to("m") {
      width: 100%;
    }
    @include respond-to("s") {
      height: 364.87px;
    }
  }

  .contactItem {
    width: 33.33%;
    @include respond-to("xl") {
      width: calc((100% / 3) - 20px);
    }
    @include respond-to("m") {
      width: calc((100% / 2) - 20px);
    }
    @include respond-to("s") {
      width: 100%;
    }
  }
}

.noBorder {
  border: none;
}
