.building {
  .section-staff {
    @include respond-to("s") {
      margin: 20px 0;
    }
  }
  .section-title-upper {
    @include respond-to("s") {
      text-align: left;
    }
  }
}

.section-building {
  p {
    line-height: 156.5%;
    color: var(--superColorText);
  }

  .building-switcher {
    margin-bottom: 30px;
    @include respond-to("s") {
      justify-content: space-between;
    }

    > div {
      font-size: 1.5rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      line-height: 132.5%;
      font-weight: 600;
      margin-right: 50px;
      cursor: pointer;
      margin-top: 35px;
      padding-bottom: 5px;
      color: var(--superColorText);
      @include respond-to("s") {
        margin-right: 0;
      }

      &.is-active {
        border-bottom: 4px solid var(--primaryColor);
      }

      &:hover {
        color: $primary-color;
      }
    }
  }

  .container-picture {
    margin-bottom: 30px;
  }
}

.building-picture {
  animation: fadeIn 0.4s;
  display: grid;
  grid-template-columns: repeat(4, calc(100% / 4 - 15px));
  grid-gap: 20px;
  @include respond-to("s") {
    grid-template-columns: repeat(2, calc(100% / 2 - 5px));
    grid-gap: 8px;
  }

  a {
    width: 100%;
    // height: 139px;
    // @include respond-to('s') {
    //   height: 101px;
    // }

    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
}

.building-walk {
  animation: fadeIn 0.4s;
  iframe {
    width: 100%;
    height: 500px;
    @include respond-to("s") {
      height: 190px;
    }
  }
}

.building-video {
  animation: fadeIn 0.4s;
  display: grid;
  grid-template-columns: repeat(2, calc(100% / 2 - 15px));
  grid-gap: 20px;
  @include respond-to("s") {
    grid-template-columns: 100%;
  }

  .xs-span {
    margin-top: 20px;
    letter-spacing: 0.01em;
    line-height: 132.5%;
    color: var(--superColorText);
  }

  iframe {
    width: 100%;
    height: 240px;
    @include respond-to("s") {
      height: 195px;
    }
  }
}
