.lists-block{
  .section-title-upper {
    @include respond-to('s') {
      text-align: left;
      margin-bottom: 25px;
    }
  }
  .block-item.image {
    & + .block-item.image {
      @include respond-to('s') {
        margin: 30px 0;
      }
    }
  }
  .block-content {
    @include respond-to('s') {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column-reverse;
      padding-bottom: 30px;
    }
  }
  .block-data {
    @include respond-to('s') {
      margin-bottom: 10px;
      color: var(--grayTextData);
      font-weight: 600;
    }
  }
  .section-staff {
    @include respond-to('m') {
      margin: 20px 0;
    }
  }
}