@import url(../../node_modules/swiper/css/swiper.min.css);
@import url(../../node_modules/lightpick/css/lightpick.css);
@import url(../../node_modules/flatpickr/dist/flatpickr.min.css);
@import url(../../node_modules/lightbox2/dist/css/lightbox.min.css);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* raleway-300 - latin */
@font-face {
  font-family: Raleway;
  font-weight: 300;
  src: url(../assets/font/Raleway-Light.ttf);
}

/* raleway-regular - latin */
@font-face {
  font-family: Raleway;
  font-weight: 400;
  src: url(../assets/font/Raleway-Regular.ttf);
}

/* raleway-500 - latin */
@font-face {
  font-family: Raleway;
  font-weight: 500;
  src: url(../assets/font/Raleway-Medium.ttf);
}

/* raleway-600 - latin */
@font-face {
  font-family: Raleway;
  font-weight: 600;
  src: url(../assets/font/Raleway-SemiBold.ttf);
}

body {
  font-family: 'Raleway';
  background-color: var(--superColorBg);
}

.to-flex, .to-flex-cl, .to-flex-cl-end, .to-flex-end, .to-flex-column-end, .to-flex-wrap {
  display: flex;
}

.to-flex-jc, .to-flex-jc-ai {
  display: flex;
  justify-content: space-between;
}

.to-flex-ai {
  display: flex;
  align-items: center;
}

.to-flex-jc-ai {
  align-items: center;
}

.to-flex-cl, .to-flex-cl-end {
  flex-direction: column;
}

.to-flex-cl-end {
  align-items: flex-end;
}

.to-flex-rowR {
  flex-direction: row-reverse;
}

.to-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.to-flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.to-flex-cl-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.to-flex-cl-jc {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.to-flex-end, .to-flex-column-end {
  justify-content: flex-end;
}

.to-flex-column-end {
  flex-direction: column;
}

.to-flex-wrap {
  flex-wrap: wrap;
}

.xss-span {
  font-size: 1.2rem;
  color: var(--superColorText);
}

.xs13-span {
  font-size: 1.3rem;
  color: var(--superColorText);
}

.xs-span {
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .xs-span {
    font-size: 1.3rem;
    font-weight: 500;
  }
}

.xs-span.bold {
  font-weight: 600;
}

.section-title, .section-title-upper {
  color: var(--superColorText);
  font-weight: 600;
  font-size: 1.5rem;
  width: 100%;
}

@media (max-width: 768px) {
  .section-title, .section-title-upper {
    font-size: 1.4rem;
  }
}

.section-title-upper {
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .section-title-upper {
    text-align: center;
  }
}

.section-title.mr-20, .mr-20.section-title-upper {
  margin: 20px 0;
}

.section-mini-title {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #222222;
}

.span-big {
  font-size: 1.8rem;
  color: var(--superColorText);
}

.primary-text-color {
  color: #D2AB67;
}

@media (max-width: 992px) {
  .cut-text {
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

.cut-text-wrap {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cut-text-wrap.wrap-2 {
  -webkit-line-clamp: 2;
}

.btn, .primary-btn, .primary-btn-light {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 13px 33px;
  cursor: pointer;
  border: 1px solid var(--primaryColor);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .btn, .primary-btn, .primary-btn-light {
    font-size: 1.1rem;
  }
}

@media (max-width: 320px) {
  .btn, .primary-btn, .primary-btn-light {
    font-size: 1rem;
  }
}

.btn:focus, .primary-btn:focus, .primary-btn-light:focus, .btn:active, .primary-btn:active, .primary-btn-light:active {
  outline: none;
}

.btn.disabled, .btn.error-ticket, .error-ticket.primary-btn, .error-ticket.primary-btn-light, .disabled.primary-btn, .disabled.primary-btn-light {
  opacity: 0.5;
  pointer-events: none;
}

.primary-btn {
  background-color: var(--primaryColor);
  color: var(--superColorBg);
  transition: all 0.3s ease-in-out;
}

.primary-btn:hover {
  background-color: #a37930;
  border-color: #a37930;
}

.primary-btn-light {
  background-color: var(--superColorBg2);
  color: var(--primaryColor2);
  transition: all 0.3s ease-in-out;
}

.primary-btn-light:hover {
  background-color: #a37930;
  border-color: #a37930;
  color: var(--primaryColor2);
}

.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--superGray);
  color: var(--superGray);
  font-weight: 600;
  cursor: pointer;
  width: 58px;
  font-size: 1.2rem;
  height: 43px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  transition: all 0.3s ease-in-out;
}

.pagination-btn.is-active {
  background-color: var(--primaryColor);
  color: var(--superColorBg);
  border: 1px solid var(--primaryColor);
}

.pagination-btn.is-active:hover {
  background-color: #a37930;
  border-color: #a37930;
}

.pagination-btn:hover {
  background-color: #e1e1e1;
}

.light-btn {
  width: 33px;
  height: 33px;
  border: 1px solid var(--primaryColor);
  background-color: var(--superColorBg2);
  margin-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.light-btn path {
  fill: var(--primaryColor2);
  stroke-width: 0;
}

.light-btn.rotate {
  transform: rotate(180deg);
}

.light-btn:hover {
  background-color: #D2AB67;
}

.light-btn:hover path {
  fill: #fff;
}

.downloadFile {
  padding: 20px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  color: var(--superColorText);
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}

.downloadFile svg {
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.downloadFile svg path {
  fill: var(--superColorText);
}

.downloadFile:hover {
  color: #D2AB67;
}

.downloadFile:hover svg {
  animation: .8s animationArrowDown infinite;
}

.downloadFile:hover svg path {
  fill: #D2AB67;
}

@keyframes animationArrow {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes animationArrowDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateX(-3px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  padding-top: 10px;
}

@media (max-width: 992px) {
  .header-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .header-navigation {
    position: fixed;
    background-color: var(--primaryColor2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 2;
    transform: translateX(100%);
    transition: all ease-out 0.8s;
  }
}

.header-navigation.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
  transform: translateX(0%);
}

.header-navigation .hamburger-menu {
  padding-left: 19px;
}

@media (max-width: 320px) {
  .header-navigation .hamburger-menu {
    padding-left: 0;
  }
}

.header-navigation__wrapper {
  height: 60px;
}

@media (max-width: 992px) {
  .header-navigation__wrapper {
    height: 100%;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 768px) {
  .header-navigation__wrapper {
    max-width: 540px;
  }
}

@media (max-width: 576px) {
  .header-navigation__wrapper {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 320px) {
  .header-navigation__wrapper {
    max-width: 320px;
  }
}

.header-navigation__heading {
  display: none;
}

@media (max-width: 992px) {
  .header-navigation__heading--mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 24px;
  }
}

.header-navigation__heading--mobile .search-section {
  cursor: pointer;
  margin-left: 31px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 320px) {
  .header-navigation__heading--mobile .search-section {
    margin-left: 5px;
  }
}

.header-navigation__heading--mobile .search-section svg path:first-child {
  fill: var(--superColorBg2);
  transition: all 0.3s ease-in-out;
}

.header-navigation__heading--mobile .search-section svg path:first-child:hover {
  fill: #222222;
}

.header-navigation__heading--mobile .dynamic-fontSize {
  margin-right: 26px;
}

.header-navigation__heading--mobile .dynamic-fontSize .span-big {
  color: var(--superColorBg2);
  cursor: pointer;
  font-weight: 600;
}

.header-navigation__heading--mobile .dynamic-fontSize .span-big:nth-child(2) {
  margin-left: 18px;
}

.header-navigation__heading--mobile .dynamic-fontSize .span-big:nth-child(3) {
  margin-left: 26px;
}

.header-navigation__heading--mobile .dynamic-fontSize .span-big:hover {
  color: #222222;
}

.header-navigation__heading--mobile .view-option svg path {
  fill: var(--superColorBg2);
}

.header-navigation__heading--mobile .language-option > a, .header-navigation__heading--mobile .language-option div {
  border: 1px solid var(--superColorBg2);
}

.header-navigation__heading--mobile .language-option > a:hover, .header-navigation__heading--mobile .language-option div:hover {
  background-color: #fff;
  color: #D2AB67;
}

.header-navigation__heading--mobile .language-option a {
  text-decoration: none;
  margin: 0;
}

.header-navigation__heading--mobile .language-option a + a {
  margin-left: 9px;
}

.header-navigation__info {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 320px) {
  .header-navigation__info {
    justify-content: flex-start;
  }
}

.separate-div {
  width: 1px;
  height: 85%;
  background-color: var(--primaryColor);
  margin: 0 30px;
}

@media (max-width: 1200px) {
  .separate-div {
    margin: 0 20px;
  }
}

@media (max-width: 992px) {
  .separate-div, .header-rightSide, .info-text, .logo-M, .header-floor::after {
    display: none;
  }
}

.header-floor {
  padding-bottom: 10px;
  position: relative;
}

.header-floor:after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  background-color: var(--primaryColor);
}

.header-leftSide .info-text {
  text-transform: uppercase;
  max-width: 150px;
  font-weight: 500;
  font-size: 11px;
  color: var(--superColorText);
}

.logo-M.clicked svg path {
  fill: #fff;
}

.logo-M {
  background-image: url("../assets/images/logo/logoM.png");
  background-repeat: no-repeat;
  width: 81px;
  height: 39px;
  background-position: center;
  background-size: cover;
}

.logo-M.clicked {
  background-image: url("../assets/images/logo/logoM-white.png");
}

@media (max-width: 992px) {
  .logo-M {
    display: none;
  }
}

.logo-FK {
  background-image: url("../assets/images/logo/logo.png");
  background-repeat: no-repeat;
  width: 198px;
  height: 53px;
  background-position: center;
  background-size: cover;
}

.logo-FK.clicked {
  background-image: url("../assets/images/logo/logo-white.png");
}

@media (max-width: 768px) {
  .logo-FK {
    width: 147px;
    height: 39px;
  }
}

@media (max-width: 768px) {
  .logo-FK svg {
    width: 147px;
    height: 39px;
  }
}

.logo-FK.clicked svg path {
  fill: #fff;
}

.header-rightSide .language-option > div, .header-rightSide .language-option a {
  text-decoration: none;
}

.header-rightSide .language-option > div.is-active, .header-rightSide .language-option a.is-active {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.header-rightSide .language-option > div.is-active span, .header-rightSide .language-option a.is-active span {
  color: var(--superColorBg);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 992px) {
  .header-rightSide .language-option > div.is-active span, .header-rightSide .language-option a.is-active span {
    color: var(--superColorBg2);
  }
}

.header-rightSide .language-option > div.is-active:hover span, .header-rightSide .language-option a.is-active:hover span {
  color: #D2AB67;
}

@media (max-width: 1200px) {
  .header-rightSide .dynamic-fontSize {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.header-rightSide .dynamic-fontSize > span {
  margin-right: 23px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.header-rightSide .dynamic-fontSize > span.is-active {
  color: #D2AB67;
}

.header-rightSide .dynamic-fontSize > span:hover {
  color: #D2AB67;
}

.header-rightSide .search-section {
  margin-left: 50px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1200px) {
  .header-rightSide .search-section {
    margin-left: 13px;
  }
}

.header-rightSide .search-section svg path:first-child {
  fill: var(--superColorText);
  transition: all 0.3s ease-in-out;
}

.header-rightSide .search-section svg path:last-child {
  fill: var(--superColorBg);
}

.header-rightSide .search-section svg {
  margin-right: 18px;
}

.header-rightSide .search-section:hover {
  color: #D2AB67;
}

.header-rightSide .search-section:hover svg path:first-child {
  fill: #D2AB67;
}

.social-media > div, .social-media a, .language-option > div, .language-option a {
  border: 1px solid var(--superColorText);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  cursor: pointer;
}

@media (max-width: 1315px) {
  .social-media > div, .social-media a, .language-option > div, .language-option a {
    margin-right: 5px;
  }
}

.social-media > div:last-child, .social-media a:last-child, .language-option > div:last-child, .language-option a:last-child {
  margin-right: 0;
}

.social-media > div svg path, .social-media a svg path, .language-option > div svg path, .language-option a svg path {
  fill: var(--superColorText);
}

.social-media > div span, .social-media a span, .language-option > div span, .language-option a span {
  color: var(--superColorText);
}

@media (max-width: 992px) {
  .social-media > div span, .social-media a span, .language-option > div span, .language-option a span {
    color: var(--superColorBg2);
  }
}

.social-media .view-option.clicked, .language-option .view-option.clicked {
  background-color: yellow;
  border-color: yellow;
}

.social-media .view-option.clicked svg path, .language-option .view-option.clicked svg path {
  fill: #000;
}

.social-media > div, .social-media a, .language-option > div, .language-option a {
  transition: all 0.3s ease-in-out;
}

.social-media > div svg path, .social-media > div span, .social-media a svg path, .social-media a span, .language-option > div svg path, .language-option > div span, .language-option a svg path, .language-option a span {
  transition: all 0.3s ease-in-out;
}

.social-media > div:hover, .social-media a:hover, .language-option > div:hover, .language-option a:hover {
  border-color: #D2AB67;
}

.social-media > div:hover svg path, .social-media a:hover svg path, .language-option > div:hover svg path, .language-option a:hover svg path {
  fill: #D2AB67;
}

.social-media > div:hover span, .social-media a:hover span, .language-option > div:hover span, .language-option a:hover span {
  color: #D2AB67;
}

.social-media > div:hover.is-active, .social-media a:hover.is-active, .language-option > div:hover.is-active, .language-option a:hover.is-active {
  background-color: #fff;
}

.header-navigation .menu {
  height: 100%;
}

@media (max-width: 992px) {
  .header-navigation .menu {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu li {
    border-bottom: 1px solid var(--superGrayYellow);
  }
}

.header-navigation .menu a {
  text-decoration: none;
  color: var(--superColorText);
  position: relative;
  padding-bottom: 2px;
  font-weight: 600;
}

@media (max-width: 992px) {
  .header-navigation .menu a {
    color: var(--superColorBg2);
    padding-bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.header-navigation .menu .menu-item {
  font-size: 1.3rem;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.header-navigation .menu .menu-item + .menu-item {
  margin-left: 40px;
}

@media (max-width: 1315px) {
  .header-navigation .menu .menu-item + .menu-item {
    margin-left: 30px;
  }
}

@media (max-width: 1200px) {
  .header-navigation .menu .menu-item + .menu-item {
    margin-left: 18px;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu .menu-item + .menu-item {
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu .menu-item {
    font-size: 1.4rem;
    height: 50px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item .main-link {
    transition: all 0.3s ease-in-out;
  }
  .header-navigation .menu .menu-item.is-actived .main-link {
    color: #D2AB67;
    text-decoration: none;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .main-link {
    color: #222222;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .item-submenu {
    display: block;
    animation: .4s fadeIn;
    padding-top: 5px;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .item-submenu {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .dropdown-menu:not(.is-show) {
    display: block;
    animation: .4s fadeIn;
    min-width: 250px;
    padding-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .dropdown-menu:not(.is-show) {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .dropdown-submenu {
    position: relative;
    display: flex;
    align-items: center;
  }
  .header-navigation .menu .menu-item.is-actived .dropdown-submenu .back {
    display: none;
  }
  .header-navigation .menu .menu-item.is-actived .dropdown-submenu.is-actived .dropdown-menu {
    top: -10px;
    display: block;
    animation: .4s fadeIn;
    margin-top: 8px;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .dropdown-submenu.is-actived .dropdown-menu {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .dropdown-submenu:hover .dropdown-menu {
    top: -10px;
    display: block;
    animation: .4s fadeIn;
    margin-top: 8px;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item.is-actived .dropdown-submenu:hover .dropdown-menu {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item:hover .main-link {
    color: #D2AB67;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item:hover .main-link {
    color: #222222;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item:hover .item-submenu {
    display: block;
    animation: .4s fadeIn;
    padding-top: 5px;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item:hover .item-submenu {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item:hover .dropdown-menu:not(.is-show) {
    display: block;
    animation: .4s fadeIn;
    min-width: 250px;
    padding-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item:hover .dropdown-menu:not(.is-show) {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-navigation .menu .menu-item:hover .dropdown-submenu {
    position: relative;
    display: flex;
    align-items: center;
  }
  .header-navigation .menu .menu-item:hover .dropdown-submenu .back {
    display: none;
  }
  .header-navigation .menu .menu-item:hover .dropdown-submenu:hover .dropdown-menu {
    top: -10px;
    display: block;
    animation: .4s fadeIn;
    margin-top: 8px;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .header-navigation .menu .menu-item:hover .dropdown-submenu:hover .dropdown-menu {
    display: none;
  }
}

.header-navigation .menu .menu-item.is-active .main-link:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: var(--primaryColor);
  position: absolute;
  bottom: -5px;
  left: 0;
}

@media (max-width: 992px) {
  .header-navigation .menu .menu-item.is-active .main-link:after {
    display: none;
  }
}

.header-navigation .menu .menu-item .main-arrow {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform: rotate(270deg);
  margin-bottom: 4px;
}

@media (max-width: 992px) {
  .header-navigation .menu .menu-item .main-arrow {
    width: 20%;
    transform: rotate(0deg);
  }
}

.header-navigation .menu .menu-item .main-arrow.arrowGlobal {
  display: none;
}

@media (max-width: 992px) {
  .header-navigation .menu .menu-item .main-arrow.arrowGlobal {
    display: flex;
  }
}

.header-navigation .menu .menu-item .item-submenu {
  top: 70%;
  left: 0;
}

.header-navigation .menu .menu-item .item-submenu-next {
  left: 100%;
  top: 0;
}

.header-navigation .menu .item-submenu, .header-navigation .menu .dropdown-menu {
  position: absolute;
  top: 70%;
  left: 0;
  display: none;
  width: fit-content;
  height: fit-content;
  background-color: var(--primaryColor2);
  border: 1px solid var(--primaryColor);
  z-index: 111111;
}

.header-navigation .menu .item-submenu a, .header-navigation .menu .dropdown-menu a {
  color: var(--superColorBg2);
  white-space: nowrap;
  text-transform: capitalize;
}

@media (max-width: 992px) {
  .header-navigation .menu .item-submenu a, .header-navigation .menu .dropdown-menu a {
    white-space: unset;
    width: 100%;
  }
}

.header-navigation .menu .item-submenu a .arrow-down-mobile, .header-navigation .menu .dropdown-menu a .arrow-down-mobile {
  margin-left: 20px;
  transform: rotate(-90deg);
}

@media (max-width: 992px) {
  .header-navigation .menu .item-submenu a .arrow-down-mobile, .header-navigation .menu .dropdown-menu a .arrow-down-mobile {
    margin-left: 0;
    transform: rotate(0);
  }
}

.header-navigation .menu .item-submenu a:hover, .header-navigation .menu .dropdown-menu a:hover {
  border-bottom: 1px solid var(--superColorBg2);
}

.header-navigation .menu .item-submenu li, .header-navigation .menu .dropdown-menu li {
  margin-bottom: 0;
  padding: 10px 20px 10px 20px;
}

.header-navigation .menu .item-submenu .dropdown-menu, .header-navigation .menu .dropdown-menu .dropdown-menu {
  top: 0;
  left: 100%;
}

@media (max-width: 992px) {
  .header-navigation .menu .item-submenu .dropdown-menu, .header-navigation .menu .dropdown-menu .dropdown-menu {
    left: 0;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd {
    position: relative;
    top: 0;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .arrow-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

.header-navigation .menu--rwd .arrow-down-mobile, .header-navigation .menu--rwd .arrow-left-mobile {
  fill: var(--superColorBg2);
  stroke: var(--superColorBg2);
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .arrow-down-mobile {
    margin-right: 10px;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .menu-item {
    position: unset;
    margin: 0;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .open-sub {
    background-color: var(--primaryColor2);
    display: block !important;
    top: 0 !important;
    height: 100vh;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .item-submenu li {
    padding: 0;
    margin-bottom: 0;
    height: 50px;
    display: flex;
  }
}

.header-navigation .menu--rwd .item-submenu a {
  text-transform: uppercase;
  padding-right: 20px;
  min-height: 16px;
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .item-submenu a:hover {
    border-bottom: none;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .dropdown-submenu {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .dropdown-submenu a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header-navigation .menu--rwd .back {
  display: none;
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .back {
    display: block;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .back a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .header-navigation .menu--rwd .back .arrow-left-mobile {
    margin-right: 21px;
  }
}

/* Defines the animation keyframes */
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Defines the animation keyframes */
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  72% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  72% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  72% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.search-input {
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid var(--superColorBg2);
  padding: 10px 0;
  font-family: Raleway, serif;
  margin-right: 20px;
  height: 39px;
  font-weight: 500;
  font-size: 2.0rem;
  color: var(--superColorBg2);
}

@media (max-width: 992px) {
  .search-input {
    margin-right: 0;
  }
}

.search-input:focus, .search-input:active {
  outline: none;
}

.search-input::placeholder {
  color: var(--superColorBg2);
}

.search-blur {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: var(--primaryColor2);
  opacity: .9;
}

@media (max-width: 992px) {
  .search-blur {
    flex-direction: column;
    opacity: 1;
  }
}

.search-blur .closeIcon {
  position: absolute;
  right: 31%;
  top: 35%;
  cursor: pointer;
}

@media (max-width: 992px) {
  .search-blur .closeIcon {
    right: 20px;
    top: 20px;
  }
}

.search-blur .closeIcon svg path {
  fill: var(--superColorBg2);
}

@media (max-width: 992px) {
  .search-blur .closeIcon svg {
    width: 22px;
    height: 22px;
  }
}

.search-blur .error-label {
  height: 12px;
}

.search-blur .search-content {
  width: 30%;
  max-width: 30%;
}

@media (max-width: 992px) {
  .search-blur .search-content {
    width: 100%;
    max-width: 96%;
  }
}

.footer-line {
  background-color: var(--primaryColorLight2);
  border-left: none;
  border-right: none;
}

.footer-line .footer-list {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .footer-line .footer-list {
    flex-direction: column;
    text-align: center;
    padding: 35px 0;
  }
}

@media (max-width: 768px) {
  .footer-line .footer-list .list-item + .list-item {
    padding-top: 20px;
  }
}

.footer-line .footer-list a {
  text-decoration: none;
  color: #222222;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.footer-line .footer-list a:hover {
  color: #D2AB67;
}

@media (max-width: 992px) {
  .to-flex-jc, .to-flex-jc-ai {
    flex-wrap: wrap;
  }
}

.footer-block {
  padding: 20px 0;
}

.footer-block .logo-footer-FK.clicked svg path {
  fill: #fff;
}

@media (max-width: 992px) {
  .footer-block .logo-footer-FK {
    text-align: center;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .footer-block .logo-footer-FK {
    margin-bottom: 0;
  }
}

@media (max-width: 992px) {
  .footer-block .logo-footer-FK svg {
    width: 114px;
    height: 98px;
  }
}

@media (max-width: 768px) {
  .footer-block .block-column {
    width: 50%;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .footer-block .block-column:first-child {
    width: 100%;
  }
}

.footer-block .block-column:nth-child(3) {
  margin-top: 27px;
}

.footer-block .block-column--title {
  margin-bottom: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--superColorText);
}

.footer-block .block-column a {
  text-decoration: none;
  margin: 5px 0;
  color: var(--superColorText);
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.footer-block .block-column a:hover {
  color: #D2AB67;
}

@media (max-width: 768px) {
  .footer-block .block-column .social-media {
    justify-content: space-between;
  }
}

.footer-block .block-column .social-media a {
  margin-right: 18px;
}

@media (max-width: 768px) {
  .footer-block .block-column .social-media a {
    margin-right: 0;
  }
}

.footer-block .block-column .column-address {
  margin-bottom: 10px;
}

.footer-block .block-column .column-address span {
  margin-bottom: 2px;
}

.signature-Ultimate {
  border-top: 1px solid var(--primaryColor);
  padding: 5px 0;
}

@media (max-width: 768px) {
  .signature-Ultimate {
    padding: 15px 0;
  }
}

.signature-Ultimate .powered-by {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .signature-Ultimate .powered-by {
    margin-left: 0;
    margin-top: 8px;
  }
}

.signature-Ultimate .powered-by a {
  margin-left: 15px;
}

@media (max-width: 768px) {
  .signature-Ultimate .to-flex-ai {
    flex-direction: column;
  }
}

.signature-Ultimate .adminMail {
  display: block;
  text-decoration: none;
  color: #222222;
  margin-top: 7px;
}

.signature-Ultimate .adminMail:hover {
  color: #D2AB67;
}

.logo-Ultimate.clicked svg path {
  fill: #fff;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: 1258px;
  /* 1 */
  margin-left: auto;
  /* 2 */
  margin-right: auto;
  /* 2 */
  padding-left: 15px;
  /* 3 */
  padding-right: 15px;
  /* 3 */
}

@media (max-width: 1315px) {
  .container {
    max-width: 1140px;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 968px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 720px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-container {
  position: relative;
}

@media (max-width: 768px) {
  .main-container--change-size .container.change-size {
    max-width: 100%;
    padding: 0;
  }
}

.left-container {
  width: 25%;
}

.left-container .left-container--menu {
  padding: 20px 0;
}

.left-container .left-container--menu .section-title-upper {
  padding: 13px 25px;
}

.right-container {
  width: 75%;
  margin: 20px;
  min-height: 650px;
  line-height: 156.5%;
}

.right-container.noMinHeight {
  min-height: auto;
}

@media (max-width: 1200px) {
  .right-container--full-width {
    width: 100%;
    margin: 20px 0;
  }
}

.right-container a {
  color: var(--superColorText) !important;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.right-container a:hover {
  color: #D2AB67 !important;
}

.section-logos {
  display: grid;
  grid-template-columns: repeat(4, calc(100% / 4 - 15px));
  grid-gap: 20px;
  justify-items: center;
  margin-top: 20px;
}

.section-logos img {
  object-fit: scale-down;
  width: 100%;
}

.double-block {
  margin-bottom: 40px;
}

.double-block .section-title-upper {
  margin-top: 40px;
  margin-bottom: 20px;
}

.double-block .news-item {
  width: 48%;
}

.double-block .news-item--picture {
  margin-bottom: 20px;
}

.double-block p {
  margin-bottom: 20px;
}

.double-block .downloadFile {
  padding: 10px 0;
  justify-content: flex-end;
}

.dynamic-content {
  position: relative;
}

.calendar {
  padding: 20px 0;
  width: 100%;
}

@media (max-width: 768px) {
  .calendar {
    padding: 25px 0 10px;
  }
}

.calendar .calendar__header {
  padding: 10px 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar .calendar__header--month, .calendar .calendar__header--year {
  letter-spacing: 0.06em;
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--superColorText);
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@media (max-width: 768px) {
  .calendar .calendar__header--month, .calendar .calendar__header--year {
    font-size: 1.3rem;
  }
}

.calendar .calendar__header--month {
  margin-right: 10px;
}

.calendar .calendar__header--arrow {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
}

.calendar .calendar__header--arrow:hover svg path {
  fill: #D2AB67;
  stroke: #D2AB67;
}

.calendar .calendar__header--arrow svg path {
  fill: var(--superColorText);
  stroke: var(--superColorText);
}

.calendar .calendar__header--arrow.arrow-left {
  left: 18px;
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .calendar .calendar__header--arrow.arrow-left {
    left: 0;
  }
}

.calendar .calendar__header--arrow.arrow-right {
  right: 18px;
  transform: rotate(270deg);
}

@media (max-width: 768px) {
  .calendar .calendar__header--arrow.arrow-right {
    right: 0;
  }
}

.calendar .calendar__week, .calendar .calendar__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 1.3rem;
  color: var(--superColorText);
  padding: 14px 15px 0;
  grid-gap: 5px;
}

@media (max-width: 768px) {
  .calendar .calendar__week, .calendar .calendar__days {
    padding: 14px 0 0;
    grid-gap: 2px;
  }
}

.calendar .calendar__week {
  font-weight: 500;
  text-align: center;
}

@media (max-width: 768px) {
  .calendar .calendar__days {
    position: relative;
    width: 100%;
  }
}

.calendar .date {
  color: var(--superColorText);
  border-radius: 50%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-weight: 600;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  justify-self: center;
  grid-gap: 4px;
}

@media (max-width: 768px) {
  .calendar .date {
    background-color: var(--primaryColorLight);
    border-radius: 0;
    width: 100%;
    height: 31px;
    grid-gap: 1px;
  }
}

.calendar .date.out-of-scope {
  color: var(--superGrayLine);
}

.calendar .date.is-event {
  background-color: var(--primaryColor);
  color: var(--superColorBg);
  cursor: pointer;
  position: relative;
  animation: fadeInText .5s;
  transition: all 0.3s ease-in-out;
  border: 2px solid transparent;
}

.calendar .date.is-event:hover {
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  background-color: transparent;
}

@media (max-width: 768px) {
  .calendar .date.is-event {
    position: unset;
  }
}

.calendar .date .to-flex-center {
  width: 100%;
  height: 100%;
}

.load-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 99999;
  background-color: var(--superColorBg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 1rem;
}

.spinner > div {
  background-color: var(--primaryColor);
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner.small > div {
  width: 4px;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.container-for-loader {
  position: absolute;
  height: 30px;
  width: 100%;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%);
  z-index: 109;
  border-top: 1px solid red;
  transition: .3s;
}

.container-for-loader.is-active {
  bottom: 0;
}

.container-for-loader .load-container {
  position: relative;
}

.container-for-loader .load-container .spinner {
  margin: 0px auto;
  height: 25px;
}

.block-wrapper {
  padding-bottom: 20px;
}

.block-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 137.5%;
  color: var(--superColorText);
}

.block-content > div {
  font-feature-settings: 'pnum' on, 'lnum' on;
  max-width: 66%;
}

@media (max-width: 992px) {
  .block-content > div {
    max-width: 100%;
  }
}

.block-content .block-data {
  white-space: nowrap;
}

.block-item {
  border-bottom: 1px solid var(--primaryColor);
}

.block-item:hover {
  cursor: pointer;
}

.block-item:hover .section-title, .block-item:hover .section-title-upper {
  color: var(--superColorText);
}

.block-item.image {
  height: 119px;
  margin: 10px 0;
}

.block-item .section-title, .block-item .section-title-upper {
  margin-bottom: 20px;
}

.block-item img {
  float: left;
  width: 178px;
  height: 90%;
  object-fit: cover;
  padding-right: 16px;
}

.primary-pagination {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  flex-wrap: wrap;
}

.primary-pagination > div {
  margin-right: 5px;
}

.primary-pagination .pagination-dots {
  color: var(--superGray);
  display: flex;
  align-items: flex-end;
  font-size: 25px;
}

.main-section-img {
  height: 40vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom !important;
  background-size: cover;
}

@media (max-width: 768px) {
  .main-section-img {
    height: 34vh;
  }
}

.main-section-img.small {
  height: 20vh;
}

@media (max-width: 768px) {
  .main-section-img.small {
    height: 87px;
  }
}

.gallery-banner {
  height: 40vh !important;
}

@media (max-width: 768px) {
  .gallery-banner {
    height: 34vh !important;
  }
}

.section-sponsor .section-title, .section-sponsor .section-title-upper {
  margin: 20px 0;
}

.section-news .to-flex, .section-news .to-flex-cl, .section-news .to-flex-cl-end, .section-news .to-flex-end, .section-news .to-flex-column-end, .section-news .to-flex-wrap {
  width: 100%;
}

@media (max-width: 1315px) {
  .section-news .left-container {
    flex: 1;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .section-news .left-container .container-position {
    background-color: var(--superColorBg);
    border: none;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .section-news .left-container .container-position .downloadFile {
    display: none;
  }
}

.section-news .left-container .primary-btn {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .section-news .left-container .primary-btn {
    width: 100%;
  }
}

@media (max-width: 1315px) {
  .section-news .news-block {
    grid-template-columns: repeat(2, calc(100% / 2 - 15px));
  }
}

@media (max-width: 992px) {
  .section-news .news-block {
    grid-template-columns: repeat(1, calc(100%));
  }
}

@media (max-width: 768px) {
  .section-news .news-block {
    grid-gap: 31px;
    margin-top: 20px;
    grid-template-columns: repeat(2, calc(100% / 2 - 15px));
  }
}

@media (max-width: 576px) {
  .section-news .news-block {
    grid-template-columns: repeat(1, calc(100%));
  }
}

@media (max-width: 768px) {
  .section-news .news-other-event .section-title-upper {
    margin: 67px 0 0;
  }
}

@media (max-width: 992px) {
  .section-news .news-other-event .news-block {
    grid-template-columns: repeat(3, calc(100%/3 - 15px));
  }
}

@media (max-width: 768px) {
  .section-news .news-other-event .news-block {
    grid-template-columns: repeat(2, calc(100%/2 - 15px));
    margin-top: 25px;
  }
}

@media (max-width: 576px) {
  .section-news .news-other-event .news-block {
    grid-template-columns: 100%;
  }
}

@media (max-width: 768px) {
  .section-news .to-flex, .section-news .to-flex-cl, .section-news .to-flex-cl-end, .section-news .to-flex-end, .section-news .to-flex-column-end, .section-news .to-flex-wrap {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .section-news .right-container {
    width: 100%;
    margin: 20px 0;
  }
}

@media (max-width: 992px) {
  .section-news .news-container .goToLink {
    display: none;
  }
}

@media (max-width: 992px) {
  .section-news .news-container .section-title-upper {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .sponsors .to-flex-ai {
    grid-template-columns: 100%;
  }
}

.sponsors__row {
  display: grid;
  grid-template-columns: 36% 20% 36%;
  justify-content: space-between;
  margin: 20px 0;
}

.sponsors__row > div {
  height: 100%;
}

.section-picture {
  margin: auto;
}

.section-sponsor {
  display: flex;
  flex-direction: column;
}

.section-sponsor .swiper-container {
  width: 450px;
  height: 100px;
}

.section-sponsor .swiper-container img {
  width: inherit;
  height: inherit;
  object-fit: contain;
}

.twoLogo img {
  width: 50%;
  object-fit: contain;
}

.oneLogo img {
  width: 100%;
  object-fit: contain;
}

.goToLink {
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  color: var(--superColorText);
  transition: all 0.3s ease-in-out;
}

.goToLink .icon {
  margin-left: 10px;
}

.goToLink .icon svg path {
  fill: var(--superColorText);
}

.goToLink:hover {
  color: #D2AB67;
}

.goToLink:hover .icon {
  animation: .8s animationArrow infinite;
}

.goToLink:hover .icon svg path {
  fill: #D2AB67;
}

.left-container {
  height: fit-content;
  position: relative;
}

.left-container .container-position {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primaryColorLight);
  border: 1px solid var(--primaryColorLight2);
}

.left-container .primary-btn {
  width: 90%;
  padding: 15px 0;
}

.left-container .address-container {
  width: 100%;
}

@media (max-width: 768px) {
  .left-container .address-container {
    margin-top: 25px;
  }
}

.left-container .address-container span {
  color: var(--superColorText);
}

.left-container .address-container .section-title-upper {
  padding: 15px 0;
  border-bottom: 1px solid var(--primaryColor);
  margin-bottom: 7px;
}

.left-container .address-container .address-line {
  padding: 5px 0;
  font-feature-settings: 'pnum' on,'lnum' on;
}

.left-container .address-container .address-line .address-name {
  width: 20%;
}

.left-container .address-container .address-line .second-name {
  width: 80%;
}

.left-container .address-container .address-line a {
  text-decoration: none;
  color: var(--superColorText);
  transition: all 0.3s ease-in-out;
}

.left-container .address-container .address-line a:hover {
  color: #D2AB67;
}

.left-container .address-container .address-data {
  margin-bottom: 15px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .left-container .address-container .address-data {
    margin-bottom: 25px;
  }
}

.calendar-tooltip {
  position: absolute;
  top: 0;
  left: 135%;
  z-index: 99999;
  padding: 17px;
  background-color: var(--primaryColor2);
  border: 1px solid var(--primaryColor);
  cursor: default;
}

@media (max-width: 992px) {
  .calendar-tooltip {
    left: 104%;
  }
}

@media (max-width: 768px) {
  .calendar-tooltip {
    left: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .calendar-tooltip .tooltip-item {
    flex-direction: row;
  }
}

.calendar-tooltip .tooltip-item:not(:last-child) {
  margin-bottom: 10px;
}

.calendar-tooltip .tooltip-image {
  width: 102px;
  height: 85px;
  background: center no-repeat;
  background-size: cover;
  margin-right: 17px;
}

.calendar-tooltip .tooltip-content {
  color: #fff;
  width: 300px;
  display: flex;
  flex-direction: column;
  animation: fadeInText .5s;
}

@media (max-width: 768px) {
  .calendar-tooltip .tooltip-content {
    flex: 1;
  }
}

.calendar-tooltip .tooltip-content .tooltip-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 132.5%;
}

.calendar-tooltip .tooltip-content .tooltip-title {
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
}

.calendar-tooltip .tooltip-content .tooltip-text {
  width: 100%;
  font-weight: normal;
  -webkit-line-clamp: 2;
}

.calendar-tooltip .tooltip-content:hover {
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}

.calendar-tooltip:after {
  content: '';
  backdrop-filter: blur(2px);
  background-color: var(--primaryColor2);
  opacity: 0.9;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  position: absolute;
  top: -1px;
  left: -10.5px;
  width: 10px;
  height: 10px;
}

@media (max-width: 768px) {
  .calendar-tooltip:after {
    display: none;
  }
}

.calendar-tooltip__close {
  display: none;
}

@media (max-width: 768px) {
  .calendar-tooltip__close {
    display: block;
    padding: 0 0 30px;
    text-align: right;
  }
}

@media (max-width: 768px) {
  .calendar-tooltip__close svg path {
    fill: var(--superColorBg2);
  }
}

@media (max-width: 768px) {
  .newsletter .container {
    max-width: 100%;
    padding: 0;
    background-color: var(--primaryColorLight);
  }
}

.section-newsletter {
  background-color: var(--primaryColorLight);
  padding: 30px;
  margin-top: 30px;
  border: 1px solid var(--primaryColorLight2);
}

@media (max-width: 768px) {
  .section-newsletter {
    max-width: 540px;
    margin: 30px auto 0;
    padding: 20px 10px 20px;
  }
}

.section-newsletter .section-title-upper {
  min-height: 40px;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .section-newsletter .newsletter-block {
    flex-direction: column;
    margin-top: 0;
  }
}

.section-newsletter .newsletter-block > * {
  width: 48%;
}

@media (max-width: 768px) {
  .section-newsletter .newsletter-block > * {
    width: 100%;
  }
}

.section-newsletter .newsletter-block .block--content {
  line-height: 190.5%;
  font-weight: 500;
  color: var(--superColorText);
}

@media (max-width: 768px) {
  .section-newsletter .newsletter-block .block--content {
    font-size: 13px;
  }
}

.section-newsletter .newsletter-block .block-signUP {
  position: relative;
}

@media (max-width: 768px) {
  .section-newsletter .newsletter-block .block-signUP {
    padding-top: 28px;
  }
}

.section-newsletter .newsletter-block .sendedMail {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #EFEBE8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #222222;
}

.section-newsletter .newsletter-block .sendedMail svg {
  margin-right: 10px;
}

.section-newsletter .newsletter-block .newsletter-mail {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 992px) {
  .section-newsletter .newsletter-block .newsletter-mail {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 21px;
  }
}

.section-newsletter .newsletter-block .newsletter-input {
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid var(--primaryColor);
  width: 65%;
  padding: 10px 0;
  font-family: Raleway, serif;
}

@media (max-width: 1200px) {
  .section-newsletter .newsletter-block .newsletter-input {
    margin-bottom: 10px;
  }
}

@media (max-width: 992px) {
  .section-newsletter .newsletter-block .newsletter-input {
    margin-right: 13px;
  }
}

.section-newsletter .newsletter-block .newsletter-input:focus, .section-newsletter .newsletter-block .newsletter-input:active {
  outline: none;
}

.section-newsletter .newsletter-block .newsletter-input::placeholder {
  color: var(--superColorText);
}

@media (max-width: 1200px) {
  .section-newsletter .newsletter-block .newsletter-btn {
    margin-bottom: 10px;
  }
}

@media (max-width: 992px) {
  .section-newsletter .newsletter-block .newsletter-btn {
    width: 118px;
    max-width: 118px;
    padding: 12px 6px;
  }
}

.section-newsletter .newsletter-block--auth-cbx .cbx input[type=checkbox] + label:before {
  top: unset;
}

@media (max-width: 1200px) {
  .section-newsletter .newsletter-block--auth-cbx .cbx label {
    display: block;
  }
}

.section-newsletter .cbx .xs13-span {
  transition: all 0.3s ease-in-out;
}

.section-newsletter .cbx .xs13-span:hover {
  color: #D2AB67;
}

.news-container.archive .section-title-upper {
  padding: 10px 0;
}

.news-block {
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3 - 15px));
  grid-gap: 20px;
  margin-top: 20px;
  position: relative;
}

.news-item {
  cursor: pointer;
}

.news-item:hover .news-item--title {
  color: #D2AB67;
}

.news-item--rentRoom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.news-item--rentRoom > *:not(:last-child) {
  margin-bottom: 2rem;
}

.news-item--wheelechair {
  background: center/cover no-repeat;
  width: 100%;
  position: relative;
}

.news-item--picture {
  background: center/cover no-repeat;
  width: 100%;
  height: 200px;
  position: relative;
}

@media (max-width: 768px) {
  .news-item--picture {
    min-height: 209px;
  }
}

.news-item--picture .news-item--data {
  background-color: var(--superColorBg);
  font-weight: 600;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 14px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: var(--superColorText);
}

.news-item--picture .news-item--data svg {
  margin-right: 5px;
}

.news-item--picture .news-item--data svg path {
  fill: var(--superColorText);
}

.news-item--title {
  padding: 10px 0 24px 0;
  line-height: 132.5%;
  font-feature-settings: "pnum" on, "lnum" on;
  letter-spacing: 0.01em;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 992px) {
  .news-item--title {
    padding: 15px 0 10px 0;
  }
}

.news-item--content {
  line-height: 137.5%;
  color: var(--superColorText);
}

.news-item__read-more {
  display: none;
}

@media (max-width: 992px) {
  .news-item__read-more {
    display: block;
    text-decoration: none;
    color: var(--primaryColor2);
    font-size: 1.2rem;
    line-height: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 24px;
  }
}

.news-pagination {
  margin-top: 40px;
  color: var(--superColorText);
}

@media (max-width: 992px) {
  .news-pagination {
    display: none;
  }
}

.news-pagination .pagination-side {
  padding: 0 20px;
  cursor: pointer;
  width: 16px;
}

.news-pagination .pagination-side span:hover {
  color: #D2AB67;
}

.news-action {
  display: none;
}

@media (max-width: 992px) {
  .news-action {
    display: block;
    margin-top: 36px;
  }
}

.cbx {
  position: relative;
}

.cbx input[type="checkbox"] {
  display: none;
}

.cbx input[type="checkbox"] + label {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 30px;
  padding-left: 35px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: var(--superColorText);
  line-height: 1.2;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.cbx input[type="checkbox"] + label a {
  color: var(--superColorText);
}

.cbx input[type="checkbox"] + label a:hover {
  color: #D2AB67;
}

.cbx input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}

.cbx input[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primaryColor);
  position: absolute;
  left: 0;
  top: 5px;
  opacity: .6;
  -webkit-transition: all .12s, border-color .08s;
  transition: all .12s, border-color .08s;
}

.cbx input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: 1px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border: 2px solid var(--primaryColor);
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cbx.error .error-label {
  display: block;
  position: absolute;
  bottom: 9px;
  font-size: 11px;
  left: 34px;
}

.cbx.error input[type=checkbox] + label:before {
  border-color: #FF2727;
  opacity: 1;
}

.cbx.error input[type="checkbox"]:checked + label:before {
  border-top-color: transparent;
  border-left-color: transparent;
}

.search-section-content {
  margin-bottom: 50px;
}

.search-section-content .section-title-upper {
  margin: 40px 0;
}

.search-section-content .block-item, .search-section-content .block-content {
  margin-bottom: 20px;
}

.search-section-content a {
  display: block;
  text-decoration: none;
}

.search-section-content .primary-btn {
  width: 210px;
}

.search-tips {
  margin-bottom: 20px;
}

.search-tips > div {
  margin: 10px 0;
  line-height: 189.5%;
  position: relative;
  padding-left: 14px;
  color: var(--superColorText);
}

.search-tips > div:before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: var(--primaryColor);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.primary-scrollbar::-webkit-scrollbar {
  border-radius: 10px;
  width: 6px;
  height: 6px;
  background-color: #e6e7e8;
  background-color: green;
}

.primary-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 8px;
}

.primary-scrollbar::-webkit-scrollbar {
  background-color: transparent;
}

.primary-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.primary-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #D2AB67;
}

.primary-scrollbar:hover::-webkit-scrollbar {
  background-color: transparent;
}

.news-list {
  position: relative;
}

.news-list .block-item .section-title, .news-list .block-item .section-title-upper {
  transition: all 0.3s ease-in-out;
}

.news-list .block-item:hover .section-title, .news-list .block-item:hover .section-title-upper {
  color: #D2AB67;
}

.news-container.show {
  margin-bottom: 30px;
  min-height: auto;
}

.news-container.show .section-title-upper {
  margin: 10px 0 30px 0;
}

.news-container.show p {
  line-height: 156.5%;
}

.news-container.show .new-show-content__main-img {
  width: auto;
  height: 300px !important;
  float: right;
  object-fit: cover;
  margin: 20px 0 20px 20px;
}

.news-container__content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.news-other-event {
  margin-top: 40px;
}

.news-show--header {
  font-feature-settings: "pnum" on, "lnum" on;
  font-weight: 600;
  color: var(--superColorText);
  margin-bottom: 20px;
}

.new-show-content {
  color: var(--superColorText);
}

@media (max-width: 768px) {
  .archive-news .left-container {
    display: none;
  }
}

@media (max-width: 768px) {
  .archive-news .news-container .section-title-upper {
    text-align: left;
    padding: 25px 0 0;
  }
}

@media (max-width: 992px) {
  .archive-news-item .news-container.show {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .archive-news-item .news-container.show img {
    width: 100% !important;
    float: none;
    margin: 0 0 20px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .archive-news-item .news-container.show .section-title-upper {
    margin: 21px 0 36px 0;
    text-align: left;
  }
}

@media (max-width: 992px) {
  .archive-news-item .section-news .left-container {
    display: none;
  }
}

@media (max-width: 992px) {
  .archive-news-item .right-container {
    width: 100%;
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .archive-news-item .right-container {
    margin-top: 15px;
  }
}

@media (max-width: 992px) {
  .archive .image {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 28px 0;
    height: auto;
  }
}

@media (max-width: 992px) {
  .archive .image img {
    width: 100%;
    height: auto;
    padding-right: 0;
    padding-bottom: 12px;
  }
}

@media (max-width: 992px) {
  .archive .block-content {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
}

@media (max-width: 992px) {
  .archive .block-data {
    margin-bottom: 10px;
    color: var(--grayTextData);
    font-weight: 600;
  }
}

.breadcrumbs {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .breadcrumbs {
    margin-bottom: 26px;
  }
}

.breadcrumbs > span {
  position: relative;
  line-height: 16px;
  color: var(--superColorText);
}

.breadcrumbs > span:first-child {
  font-weight: 500;
}

.breadcrumbs > span:not(:first-child) {
  color: var(--superColorText);
}

.breadcrumbs-space {
  top: 7px;
  width: 13px;
  height: 1px;
  background-color: var(--superColorText);
  left: 118%;
  margin: 0 10px;
}

.breadcrumbs a {
  text-decoration: none;
  color: var(--superColorText);
  transition: all 0.3s ease-in-out;
}

.breadcrumbs a:hover {
  color: var(--primaryColor2);
}

.breadcrumbs .share-element {
  margin-left: auto;
  color: var(--superColorText);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .breadcrumbs .share-element {
    width: 60%;
    text-align: right;
  }
}

.breadcrumbs .share-element svg {
  margin-left: 10px;
}

.breadcrumbs .share-element svg path {
  transition: all 0.3s ease-in-out;
  fill: var(--superColorText);
}

.breadcrumbs .share-element a {
  text-decoration: none;
  color: var(--superColorText);
  transition: all 0.3s ease-in-out;
}

.breadcrumbs .share-element:hover a {
  color: var(--primaryColor2);
}

.breadcrumbs .share-element:hover svg path {
  fill: var(--primaryColor2);
}

@media (max-width: 992px) {
  .breadcrumbs .cut-text {
    font-size: 1.1rem;
  }
}

@media (max-width: 320px) {
  .breadcrumbs .cut-text {
    font-size: 0.9rem;
  }
}

.breadcrumbs .drop-menu-show {
  width: 150px;
  margin-left: auto;
  text-align: right;
}

@media (max-width: 768px) {
  .breadcrumbs .drop-menu-show {
    width: 79px;
  }
}

.section-staff {
  margin: 20px 0 0 20px;
}

@media (max-width: 1200px) {
  .section-staff {
    margin: 20px 0;
  }
}

.section-staff .section-title-upper {
  margin-bottom: 20px;
}

.staff-photos {
  display: grid;
  grid-template-columns: repeat(3, calc(100%/3 - 15px));
  grid-gap: 20px;
  align-items: baseline;
}

.staff-photos .person-item {
  cursor: pointer;
}

.staff-photos .person-item:hover h2 {
  color: #D2AB67;
  transition: all 0.3s ease-in-out;
}

.staff-photos img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.staff-photos h2 {
  padding-top: 10px;
  color: var(--superColorText);
}

@media (max-width: 768px) {
  .staff-photos h2 {
    min-height: 35px;
  }
}

.staff-photos h2:last-child {
  padding-bottom: 10px;
}

.staff-photos .staff-name {
  font-weight: 600;
}

@media (max-width: 576px) {
  [container-type="about-list"] .staff-photos img {
    height: 233px;
  }
}

@media (max-width: 992px) {
  [container-type="about-list"] .staff-photos {
    grid-template-columns: repeat(3, calc(100%/3 - 5px));
    grid-gap: 9px;
  }
}

@media (max-width: 768px) {
  [container-type="about-list"] .staff-photos {
    grid-template-columns: repeat(2, calc(100%/2 - 5px));
    grid-gap: 9px;
  }
}

.section-staff--info {
  padding-bottom: 30px;
}

.section-staff--info .staff-block--name {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--primaryColor);
  margin-bottom: 35px;
}

.section-staff--info .staff-block--name h1, .section-staff--info .staff-block--name h2 {
  color: var(--superColorText);
}

.section-staff--info .staff-block--name .staff-name {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 132.5%;
  letter-spacing: 0.01em;
  margin-bottom: 10px;
}

.section-staff--info p, .section-staff--info div {
  line-height: 156.5%;
  color: var(--superColorText);
}

.section-staff--info p img {
  max-width: 100%;
}

@media (max-width: 768px) {
  .section-staff--info p img {
    margin: 0 auto;
    max-width: 100%;
    width: 100% !important;
  }
}

.section-staff--info table {
  width: 100% !important;
}

@media (max-width: 576px) {
  .section-staff--info table {
    display: table-cell;
  }
}

.section-staff--info tr {
  display: flex;
}

@media (max-width: 576px) {
  .section-staff--info tr {
    flex-direction: column;
  }
}

.section-staff--info tr td {
  width: 100% !important;
}

@media (max-width: 768px) {
  .section-staff--info tr td {
    width: 50% !important;
  }
}

@media (max-width: 576px) {
  .section-staff--info tr td {
    width: 100% !important;
  }
}

@media (max-width: 576px) and (max-width: 768px) {
  .section-staff--info tr td + td {
    margin-top: 20px;
  }
}

.section-staff--info em {
  font-style: italic;
}

.section-staff--info blockquote, .section-staff--info q {
  font-style: italic;
}

.section-staff--info .staticPages .xs-span p, .section-staff--info .staticPages .xs-span span, .section-staff--info .staticPages .xs-span strong {
  color: var(--superColorText) !important;
  font-family: 'Raleway', sans-serif !important;
}

.section-staff--info em {
  line-height: 28px !important;
}

@media (max-width: 768px) {
  .section-staff--info em {
    line-height: 22px !important;
  }
}

.section-staff--info em span {
  font-size: 1.8rem !important;
}

@media (max-width: 768px) {
  .section-staff--info em span {
    font-size: 1.25rem !important;
  }
}

@media (max-width: 768px) {
  .building .section-staff {
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .building .section-title-upper {
    text-align: left;
  }
}

.section-building p {
  line-height: 156.5%;
  color: var(--superColorText);
}

.section-building .building-switcher {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .section-building .building-switcher {
    justify-content: space-between;
  }
}

.section-building .building-switcher > div {
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  line-height: 132.5%;
  font-weight: 600;
  margin-right: 50px;
  cursor: pointer;
  margin-top: 35px;
  padding-bottom: 5px;
  color: var(--superColorText);
}

@media (max-width: 768px) {
  .section-building .building-switcher > div {
    margin-right: 0;
  }
}

.section-building .building-switcher > div.is-active {
  border-bottom: 4px solid var(--primaryColor);
}

.section-building .building-switcher > div:hover {
  color: #D2AB67;
}

.section-building .container-picture {
  margin-bottom: 30px;
}

.building-picture {
  animation: fadeIn 0.4s;
  display: grid;
  grid-template-columns: repeat(4, calc(100% / 4 - 15px));
  grid-gap: 20px;
}

@media (max-width: 768px) {
  .building-picture {
    grid-template-columns: repeat(2, calc(100% / 2 - 5px));
    grid-gap: 8px;
  }
}

.building-picture a {
  width: 100%;
}

.building-picture a img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.building-walk {
  animation: fadeIn 0.4s;
}

.building-walk iframe {
  width: 100%;
  height: 500px;
}

@media (max-width: 768px) {
  .building-walk iframe {
    height: 190px;
  }
}

.building-video {
  animation: fadeIn 0.4s;
  display: grid;
  grid-template-columns: repeat(2, calc(100% / 2 - 15px));
  grid-gap: 20px;
}

@media (max-width: 768px) {
  .building-video {
    grid-template-columns: 100%;
  }
}

.building-video .xs-span {
  margin-top: 20px;
  letter-spacing: 0.01em;
  line-height: 132.5%;
  color: var(--superColorText);
}

.building-video iframe {
  width: 100%;
  height: 240px;
}

@media (max-width: 768px) {
  .building-video iframe {
    height: 195px;
  }
}

@media (max-width: 1200px) {
  .discography {
    width: 100%;
    position: relative;
  }
}

@media (max-width: 1200px) {
  .discography .section-staff {
    width: 100%;
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .discography .section-title-upper {
    text-align: left;
  }
}

.discography-list {
  display: grid;
  grid-template-columns: repeat(4, calc(100% / 4 - 15px));
  grid-gap: 20px;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .discography-list {
    grid-template-columns: repeat(3, calc(100% / 3 - 15px));
  }
}

@media (max-width: 768px) {
  .discography-list {
    grid-template-columns: repeat(2, calc(100% / 2 - 5px));
    grid-gap: 9px;
  }
}

.discography .discography-item {
  cursor: pointer;
}

.discography .discography-item:hover .discography-title .xs-span {
  color: #D2AB67;
}

@media (max-width: 768px) {
  .discography .discography-item {
    margin-bottom: 20px;
  }
}

.discography .discography-item .discography-picture {
  min-width: 100px;
  height: 100px;
  margin: 0 auto 10px;
  text-align: center;
}

.discography .discography-item .discography-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
  color: var(--superColorText);
}

.discography .discography-item .discography-title .xs-span {
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .discography .discography-item .discography-title .xs-span {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 19px;
  }
}

.discography .discography-item .discography-title span:first-child {
  margin-bottom: 5px;
}

.discography-show .section-title, .discography-show .section-title-upper {
  margin-bottom: 10px;
}

.discography-show .xs-span {
  margin-bottom: 7px;
  color: var(--superColorText);
}

.discography-show .section-authors {
  display: flex;
  justify-content: space-between;
}

.discography-show .section-media {
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .discography-show .section-media {
    flex-direction: column;
  }
}

.discography-show .section-media .section-title, .discography-show .section-media .section-title-upper {
  margin-bottom: 20px;
}

.discography-show .section-media .media-video {
  max-width: 409px;
  width: 100%;
}

@media (max-width: 992px) {
  .discography-show .section-media .media-video {
    min-width: 342.33px;
    max-width: 100%;
  }
}

.discography-show .section-media .media-video iframe {
  width: 100%;
  height: 249px;
}

@media (max-width: 768px) {
  .discography-show .section-media .media-video iframe {
    height: 208.11px;
  }
}

.discography-show .section-media > div {
  margin-top: 46px;
}

.discography-show .section-media .audio-item {
  margin-bottom: 10px;
  font-weight: 500;
}

.discography-show .section-media .audio-item audio {
  height: 30px;
  width: 100%;
}

.discography-show .section-media .audio-item audio:focus {
  outline: none;
}

@media (max-width: 768px) {
  .lists-block .section-title-upper {
    text-align: left;
    margin-bottom: 25px;
  }
}

@media (max-width: 768px) {
  .lists-block .block-item.image + .block-item.image {
    margin: 30px 0;
  }
}

@media (max-width: 768px) {
  .lists-block .block-content {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
    padding-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .lists-block .block-data {
    margin-bottom: 10px;
    color: var(--grayTextData);
    font-weight: 600;
  }
}

@media (max-width: 992px) {
  .lists-block .section-staff {
    margin: 20px 0;
  }
}

.repertoire-section {
  margin: 20px 0;
}

.repertoire-section .downloadFile {
  padding: 0;
  font-weight: 600;
}

@media (max-width: 992px) {
  .repertoire-section .downloadFile {
    display: none;
  }
}

@media (max-width: 992px) {
  .repertoire-section .section-title-upper {
    text-align: left;
  }
}

.repertoire-section .repHead {
  margin-top: 10px;
  padding: 20px 30px;
  background-color: var(--superGrayBlack);
  border: 1px solid var(--superGrayYellow);
  display: grid;
  grid-template-columns: 25% 15% 17% 15% 15%;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .repertoire-section .repHead {
    grid-template-columns: 100%;
  }
}

@media (max-width: 992px) {
  .repertoire-section .repHead .drop-down {
    padding: 10px 0;
  }
}

@media (max-width: 768px) {
  .repertoire-section .repHead .drop-down {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .repertoire-section .repHead .drop-down .select-item {
    width: 100%;
  }
}

.repertoire-section .repHead .drop-down:first-child {
  margin-left: 20px;
}

@media (max-width: 1200px) {
  .repertoire-section .repHead .drop-down:first-child {
    margin-left: 0;
  }
}

.repertoire-section .repHead .drop-down .options ul {
  background-color: var(--superGrayBlack);
}

@media (max-width: 992px) {
  .repertoire-section .repHead .drop-down .options ul {
    top: 55px;
  }
}

.repertoire-section .repHead .filter-btn {
  padding: 13px 27px;
}

@media (max-width: 1200px) {
  .repertoire-section .repHead .filter-btn {
    margin-right: 0;
  }
}

@media (max-width: 992px) {
  .repertoire-section .repHead .filter-btn {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .repertoire-section .repHead .filter-btn {
    width: 100%;
    margin-top: 10px;
  }
}

.repertoire-section .repHead #filterMonth {
  border: none;
  background-color: transparent;
  font-size: 1.3rem;
  color: var(--superColorText);
  font-family: Raleway;
  width: 100%;
}

.repertoire-section .repHead #filterMonth:focus {
  outline: none;
}

.repertoire-section .repHead #filterMonth::placeholder {
  font-size: 1.3rem;
  color: var(--superColorText);
  line-height: 137.5%;
  font-family: Raleway;
}

@media (max-width: 992px) {
  .repertoire-section .repHead .filter-search {
    padding: 10px 0;
  }
}

.repertoire-section .repHead #search-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--superColorText);
  width: 100%;
  text-decoration: none;
  color: var(--superColorText);
  padding: 10px 0;
  font-size: 1.3rem;
  line-height: 137.5%;
  font-family: Raleway;
}

.repertoire-section .repHead #search-input::placeholder {
  color: var(--superColorText);
  font-family: Raleway;
}

.repertoire-section .repHead #search-input:focus {
  outline: none;
}

@media (max-width: 992px) {
  .repertoire-section .repHead #search-input {
    width: 100%;
  }
}

.repertoire-section .repContent {
  position: relative;
  min-height: 100px;
}

.repRow {
  padding: 20px 0;
}

.repRow:hover {
  cursor: default;
}

.repRow .block-content {
  align-items: flex-start;
}

@media (max-width: 992px) {
  .repRow .block-content {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.repRow .repRow-img img {
  height: 144px;
}

@media (max-width: 768px) {
  .repRow .repRow-img img {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .repRow .repRow-img img {
    height: 96px;
  }
}

@media (max-width: 768px) {
  .repRow .repRow-img {
    width: 50%;
  }
}

.repRow .repRow-calendar {
  background-color: var(--superGrayBlack);
  border: 1px solid var(--superGrayYellow);
  color: var(--superColorText);
  margin-right: 16px;
  width: 158px;
  height: 144px;
}

@media (max-width: 992px) {
  .repRow .repRow-calendar {
    display: none;
  }
}

.repRow .repRow-calendar .calendar-date {
  font-size: 18px;
  line-height: 137%;
}

.repRow .repRow-calendar .calendar-date-big {
  font-size: 3.8rem;
  line-height: 137%;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.repRow .repRow-calendar .calendar-hour {
  font-size: 1.4rem;
  line-height: 137%;
}

.repRow .repRow-text {
  flex: 1;
}

@media (max-width: 768px) {
  .repRow .repRow-text {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .repRow .repRow-text .repRow-header {
    width: 50%;
  }
}

.repRow .repRow-text .repRow-title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 132.5%;
}

.repRow .repRow-text .repRow-data-mobile {
  display: none;
}

@media (max-width: 992px) {
  .repRow .repRow-text .repRow-data-mobile {
    display: block;
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .repRow .repRow-text .repRow-data-mobile {
    margin-top: 10px;
  }
}

.repRow .repRow-text .repRow-description {
  margin-top: 15px;
  font-size: 1.4rem;
  line-height: 137.5%;
}

@media (max-width: 768px) {
  .repRow .repRow-text .repRow-description {
    font-size: 1.3rem;
    width: 100%;
  }
}

.repRow .repRow-text .repRow-signature {
  font-size: 14px;
  line-height: 137.5%;
}

.repRow .btn-s {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 992px) {
  .repRow .btn-s {
    display: flex;
    flex-direction: row;
    margin: 20px 0 0 auto;
  }
}

@media (max-width: 768px) {
  .repRow .btn-s {
    width: 100%;
    max-width: 100%;
  }
}

.repRow .btn-s .repRow-btn, .repRow .btn-s .repRow-btn1, .repRow .btn-s .repRow-btn2 {
  font-size: 1.2rem;
  width: 145px;
  height: 38px;
  padding: 0;
  line-height: 14px;
}

@media (max-width: 768px) {
  .repRow .btn-s .repRow-btn, .repRow .btn-s .repRow-btn1, .repRow .btn-s .repRow-btn2 {
    width: 50%;
  }
}

.repRow .btn-s .repRow-btn1 {
  margin-bottom: 8px;
}

@media (max-width: 992px) {
  .repRow .btn-s .repRow-btn1 {
    margin-bottom: 0;
  }
}

.repRow .btn-s .repRow-btn2 {
  margin-top: 7px;
}

@media (max-width: 992px) {
  .repRow .btn-s .repRow-btn2 {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .repRow .btn-s .repRow-btn2 {
    margin-left: 7px;
  }
}

@media (max-width: 1200px) {
  .repertoire-show .right-container {
    flex: 1;
    width: 100%;
    margin: 20px 0 40px 20px;
  }
}

@media (max-width: 992px) {
  .repertoire-show .right-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .repertoire-show .right-container {
    margin: 20px 0 40px;
  }
}

.repertoire-show .right-container .noeditor {
  width: 407px;
  height: 260px;
  object-fit: cover;
}

@media (max-width: 992px) {
  .repertoire-show .right-container .noeditor {
    padding: 20px 0;
    float: none;
    width: 100%;
    height: auto;
  }
}

.repertoire-show .right-container .section-title-low {
  margin-top: 50px;
  margin-bottom: 15px;
  font-style: italic;
}

.repertoire-show .right-container .section-title-low:first-child {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .repertoire-show .right-container .section-title-low {
    text-align: left;
    margin-top: 20px;
  }
}

.repertoire-show .right-container .section-line {
  margin: 24px 0;
}

@media (max-width: 768px) {
  .repertoire-show .right-container .section-line {
    margin: 10px 0;
  }
}

.repertoire-show .calendar-card .border-line {
  padding: 23px 0;
  border-bottom: 1px solid var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: var(--superColorText);
}

@media (max-width: 1200px) {
  .repertoire-show .calendar-card .border-line {
    padding: 15px 0;
  }
}

@media (max-width: 992px) {
  .repertoire-show .calendar-card .border-line {
    padding: 10px 0;
  }
}

.repertoire-show .calendar-card .border-line.space {
  line-height: 137%;
  font-weight: 500;
}

.repertoire-show .calendar-card .card-day {
  font-size: 3.8rem;
  font-weight: 300;
  line-height: 137%;
}

@media (max-width: 992px) {
  .repertoire-show .calendar-card .card-day {
    font-size: 2.8rem;
  }
}

.repertoire-show .calendar-card .card-week {
  font-size: 1.8rem;
  line-height: 137%;
}

@media (max-width: 992px) {
  .repertoire-show .calendar-card .card-week {
    font-size: 1.4rem;
  }
}

.repertoire-show .calendar-card .card-hours {
  font-weight: 500;
}

.repertoire-show .calendar-card .card-price {
  padding-top: 23px;
  padding-bottom: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: var(--superColorText);
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 992px) {
  .repertoire-show .calendar-card .card-price {
    font-size: 1.1rem;
  }
}

.repertoire-show .calendar-card .primary-btn {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .repertoire-show .calendar-card--desktop {
    display: none;
  }
}

.repertoire-show .calendar-card--mobile {
  display: none;
}

@media (max-width: 768px) {
  .repertoire-show .calendar-card--mobile {
    display: block;
    background-color: var(--primaryColorLight);
    border: 1px solid var(--primaryColorLight2);
    padding: 10px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--superColorText);
  }
}

@media (max-width: 768px) {
  .repertoire-show .calendar-card--mobile .calendar-card__info span {
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .repertoire-show .calendar-card--mobile .primary-btn {
    margin-bottom: 0;
  }
}

.gallery-section {
  margin: 20px 0;
}

@media (max-width: 768px) {
  .gallery-section {
    margin: 25px 0;
  }
}

@media (max-width: 768px) {
  .gallery-section__wrapper {
    background-color: var(--primaryColorLight);
  }
}

.gallery-section .section-title-upper {
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .gallery-section .section-title-upper {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .gallery-section .section-title-upper {
    margin-bottom: 40px;
  }
}

.gallery-section .swipe-container {
  padding: 0 80px;
  background-color: var(--primaryColorLight);
  position: relative;
  border: 1px solid var(--primaryColorLight2);
}

@media (max-width: 768px) {
  .gallery-section .swipe-container {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .gallery-section .swipe-container {
    padding: 0 50px;
  }
}

.gallery-section .swipe-container a {
  text-decoration: none;
  color: var(--superColorText);
}

.gallery-section .swipe-container a:hover {
  color: #D2AB67;
  transition: all 0.3s ease-in-out;
}

.gallery-section .swiper-container {
  height: 80px;
}

@media (max-width: 768px) {
  .gallery-section .swiper-container {
    height: 51px;
  }
}

.gallery-section .swiper-container .swiper-slide {
  cursor: pointer;
}

@media (max-width: 992px) {
  .gallery-section .swiper-container .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.gallery-section .swiper-container .swiper-slide.is-active {
  font-weight: 600;
}

.gallery-section .swiper-container .swiper-slide .xs-span {
  color: var(--superColorText);
  transition: all 0.3s ease-in-out;
}

.gallery-section .swiper-container .swiper-slide .xs-span:hover {
  color: #D2AB67;
}

.gallery-section .swiper-container .swiper-pagination {
  opacity: 0;
}

.gallery-section .double-left {
  position: relative;
  cursor: pointer;
  width: 20px;
}

@media (max-width: 768px) {
  .gallery-section .double-left {
    display: none;
  }
}

.gallery-section .double-left svg path {
  transition: all 0.3s ease-in-out;
}

.gallery-section .double-left:hover svg path {
  fill: #D2AB67;
}

.gallery-section .double-left svg:last-child {
  position: absolute;
  top: 0;
  left: 5px;
}

.gallery-section .arrow-left, .gallery-section .arrow-right {
  position: absolute;
  top: 40%;
}

.gallery-section .arrow-left {
  left: 20px;
}

@media (max-width: 768px) {
  .gallery-section .arrow-left {
    left: 0;
  }
}

.gallery-section .arrow-right {
  right: 20px;
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .gallery-section .arrow-right {
    right: 0;
  }
}

.gallery-section .single-arrow-left, .gallery-section .single-arrow-right {
  margin-left: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .gallery-section .single-arrow-left, .gallery-section .single-arrow-right {
    margin-left: 0;
  }
}

.gallery-section .single-arrow-left:hover svg path, .gallery-section .single-arrow-right:hover svg path {
  fill: #D2AB67;
}

.gallery-section .single-arrow-left svg path, .gallery-section .single-arrow-right svg path {
  transition: all 0.3s ease-in-out;
  fill: var(--superColorText);
  stroke: var(--primaryColorLight);
}

.gallery-list {
  display: grid;
  grid-template-columns: repeat(4, calc(100%/4 - 15px));
  grid-gap: 20px;
  margin-top: 20px;
  position: relative;
}

@media (max-width: 992px) {
  .gallery-list {
    grid-template-columns: repeat(3, calc(100%/3 - 15px));
  }
}

@media (max-width: 768px) {
  .gallery-list {
    grid-template-columns: repeat(2, calc(100%/2 - 15px));
    margin-top: 25px;
  }
}

@media (max-width: 576px) {
  .gallery-list {
    grid-template-columns: 100%;
  }
}

@media (max-width: 768px) {
  .gallery-list .news-item--picture {
    min-height: 200px;
  }
}

@media (max-width: 768px) {
  .gallery-list .news-item--picture .news-item--data {
    padding: 14px 41px;
  }
}

.gallery-item {
  width: 298px;
  height: 175px;
}

.gallery-item img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

@media (max-width: 992px) {
  .gallery-show .left-container {
    display: none;
  }
}

@media (max-width: 992px) {
  .gallery-show .right-container {
    width: 100%;
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .gallery-show .right-container {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .gallery-show .section-gallery--swiper {
    display: none;
  }
}

.gallery-show__mobile-lightbox {
  display: none;
}

@media (max-width: 768px) {
  .gallery-show__mobile-lightbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .gallery-show__mobile-lightbox__wrapper {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .gallery-show .news-container.show .gallery-show__mobile-lightbox__pictures {
    animation: fadeIn .4s;
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2 - 5px));
    grid-gap: 8px;
  }
}

@media (max-width: 768px) {
  .gallery-show .news-container.show .gallery-show__mobile-lightbox__pictures img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    float: none;
    margin: 0;
  }
}

@media (max-width: 576px) {
  .gallery-show .news-container.show .gallery-show__mobile-lightbox__pictures img {
    height: 101px;
  }
}

@media (max-width: 768px) {
  .gallery-show .news-container.show .section-title-upper {
    margin: 21px 0 36px 0;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .gallery-show .news-show--header .xs-span {
    font-size: 1.3rem;
  }
}

.section-gallery--swiper .swiper-button-next, .section-gallery--swiper .swiper-button-prev {
  border: 1px solid var(--primaryColor2);
  background-color: var(--superColorBg2);
  padding: 10px;
  color: var(--primaryColor2);
}

.section-gallery--swiper .swiper-button-next:focus, .section-gallery--swiper .swiper-button-prev:focus {
  outline: none;
}

.section-gallery--swiper .swiper-button-next:after, .section-gallery--swiper .swiper-button-prev:after {
  font-size: 16px;
}

.section-gallery--swiper .swiper-button-next:hover, .section-gallery--swiper .swiper-button-prev:hover {
  background-color: #D2AB67;
  color: #fff;
}

.section-gallery--swiper .swiper-button-prev {
  left: 0;
}

.section-gallery--swiper .swiper-button-next {
  right: 0;
}

.section-gallery--swiper .gallery-top {
  height: 480px;
}

.section-gallery--swiper .gallery-top .swiper-slide {
  width: 100% !important;
  margin-right: 0;
}

.section-gallery--swiper .gallery-top .swiper-slide .swiper-lightbox {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-gallery--swiper .gallery-thumbs .swiper-wrapper {
  transform: translate3d(0, 0, 0) !important;
  flex-wrap: wrap;
}

.section-gallery--swiper .gallery-thumbs .swiper-wrapper .swiper-slide {
  height: 120px;
  margin: 6px 0;
}

.section-gallery--swiper .gallery-thumbs .swiper-wrapper .swiper-slide:nth-child(3n) {
  margin-right: 9px !important;
}

.section-gallery--swiper .gallery-thumbs .swiper-wrapper .swiper-slide:nth-child(4n) {
  margin-right: 0 !important;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.gallery-top {
  height: 80%;
  width: 100%;
}

.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

@media (max-width: 768px) {
  [container-type="cycles"] .section-title-upper {
    text-align: left;
  }
}

@media (max-width: 768px) {
  [container-type="cycles"] .repertoire-section .repHead {
    grid-template-columns: 100%;
  }
}

@media (max-width: 992px) {
  [container-type="cycles"] .repertoire-section .repHead .drop-down {
    width: 100%;
    padding: 10px 0;
  }
}

@media (max-width: 768px) {
  [container-type="cycles"] .repertoire-section .repHead .drop-down .select-item {
    width: 100%;
  }
}

[container-type="cycles"] .repertoire-section .repHead .drop-down:first-child {
  margin-left: 20px;
}

@media (max-width: 1200px) {
  [container-type="cycles"] .repertoire-section .repHead .drop-down:first-child {
    margin-left: 0;
  }
}

[container-type="cycles"] .repertoire-section .repHead .drop-down .options ul {
  background-color: var(--superGrayBlack);
}

[container-type="cycles"] .repertoire-section .repHead .select-item {
  width: 100%;
}

[container-type="cycles"] .repertoire-section .block-item:hover {
  cursor: unset;
}

@media (max-width: 1315px) {
  [container-type="cycles"] .drop-down .selected .select-item {
    font-size: 1.1rem;
  }
}

@media (max-width: 1200px) {
  [container-type="cycles"] .drop-down .selected .select-item {
    font-size: 1.3rem;
  }
}

.list-downloadFile .section-title, .list-downloadFile .section-title-upper {
  margin: 30px 0;
}

.list-downloadFile .file-item:not(:last-child) {
  margin-bottom: 10px;
}

.list-downloadFile svg {
  margin-right: 10px;
}

.list-downloadFile svg path {
  fill: var(--superColorText);
}

.list-downloadFile a {
  color: var(--superColorText);
  text-decoration: none;
}

.list-downloadFile a:hover {
  color: #D2AB67;
}

.list-with-title {
  margin-bottom: 20px;
  color: var(--superColorText);
}

.list-with-title .section-title, .list-with-title .section-title-upper {
  margin-top: 20px;
  margin-bottom: 10px;
}

.list-with-title ul {
  line-height: 156.5%;
}

.contactContainer {
  margin-top: 10px;
  padding: 20px 0;
}

.contactContainer__row {
  flex-wrap: wrap;
  margin-top: 0;
  border: 0;
}

.contactContainer__row .contactItem {
  padding: 25px 0 15px 0;
  border-top: 1px solid var(--primaryColor);
}

@media (max-width: 768px) {
  .contactContainer {
    margin-top: 4px;
  }
}

.contactContainer a {
  color: var(--superColorText);
  text-decoration: none;
  /* no underline */
}

.contactContainer p {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 24px;
}

.contactContainer p > *:first-child {
  margin-top: 1.8rem;
}

.contactContainer p > *:not(:last-child) {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .contactContainer p {
    font-size: 1.3rem;
    line-height: 22px;
    margin-bottom: 29px;
  }
}

.contactContainer a:hover {
  color: #D2AB67;
}

.contactContainer .contactTitle {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 132.5%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .contactContainer .contactTitle {
    font-size: 1.4rem;
    margin-bottom: 27px;
  }
}

.contactContainer .contactBold {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 132.5%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .contactContainer .contactBold {
    font-size: 1.3rem;
  }
}

.contactContainer .contactText {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 156.5%;
  margin-bottom: 17px;
}

.contactContainer .contactText > *:first-child {
  margin-top: 1.8rem;
}

.contactContainer .contactText > *:not(:last-child) {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .contactContainer .contactText {
    font-size: 1.3rem;
    line-height: 22px;
    margin-bottom: 29px;
  }
}

.contactContainer .contactTextLast {
  margin-bottom: 80px;
}

.contactContainer .contactMap {
  width: 100%;
  height: 600px;
}

@media (max-width: 992px) {
  .contactContainer .contactMap {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contactContainer .contactMap {
    height: 364.87px;
  }
}

.contactContainer .contactItem {
  width: 33.33%;
}

@media (max-width: 1315px) {
  .contactContainer .contactItem {
    width: calc((100% / 3) - 20px);
  }
}

@media (max-width: 992px) {
  .contactContainer .contactItem {
    width: calc((100% / 2) - 20px);
  }
}

@media (max-width: 768px) {
  .contactContainer .contactItem {
    width: 100%;
  }
}

.noBorder {
  border: none;
}

@media (max-width: 768px) {
  .directorate .section-title-upper {
    text-align: left;
    margin-bottom: 25px;
  }
}

@media (max-width: 576px) {
  .directorate .section-staff--info .xs-span {
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .directorate .section-staff--info img {
    width: 165px;
    height: 233px;
    padding: 0;
    margin-bottom: 20px;
    margin-left: 20px;
  }
}

@media (max-width: 576px) {
  .directorate .section-staff--info .staff-block--name {
    margin-bottom: 0;
  }
}

.directorate .section-staff--info .downloadFile {
  width: 100%;
  text-align: right;
  padding: 20px 0;
}

.reservationPage {
  margin: 50px 0;
  width: 100%;
}

.reservationPage .section-title-upper, .reservationPage .section-title, .reservationPage .section-title-upper {
  margin-bottom: 20px;
}

.reservation-seats {
  margin-bottom: 40px;
  align-items: start;
}

@media (max-width: 1200px) {
  .reservation-seats {
    flex-direction: column;
  }
}

.reservation-wrapper {
  width: 920px;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin-right: 20px;
}

.reservation-zoom {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .reservation-zoom {
    display: flex;
    justify-content: space-between;
  }
}

.reservation-zoom i {
  margin-left: 5px;
}

.reservation-zoom div {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .reservation-zoom div {
    width: calc((100% / 3) - 5px);
    margin-right: 0;
  }
}

.reservation-room {
  margin: 0 30px;
  display: inline-flex;
}

@media (max-width: 768px) {
  .reservation-room {
    zoom: 0.7;
  }
}

@media (max-width: 576px) {
  .reservation-room {
    zoom: 0.5;
  }
}

.reservation-room .right-side {
  margin-top: 50px;
  margin-left: 40px;
}

.reservation-room .right-side .space {
  margin-right: 6px;
  margin-bottom: 10px;
}

.reservation-room .section-mini-title {
  margin-bottom: 11px;
  color: var(--superColorText);
}

.reservation-room .reservation-row--space {
  position: relative;
  width: fit-content;
}

.reservation-room .reservation-row--space .space {
  margin-right: 6px;
  margin-bottom: 10px;
}

.reservation-room .reservation-row--space:before {
  content: attr(row-number);
  position: absolute;
  width: 30px;
  color: var(--superColorText);
  left: -40px;
  top: 0;
  text-align: end;
}

.reservation-room .reservation-row--space:after {
  content: attr(row-number);
  position: absolute;
  width: 30px;
  color: var(--superColorText);
  right: -50px;
  top: 0;
}

.reservation-room .reservation-row--space.plus-padding {
  padding-left: 10px;
}

.reservation-room .reservation-row--space.plus-padding:after {
  right: -40px;
}

.reservation-room .balcony {
  margin-top: 23px;
}

.reservation-room .free-space {
  cursor: pointer;
}

.reservation-room .free-space:hover {
  background-color: #a37930;
}

.reservation-room .choose-space {
  cursor: pointer;
}

.reservation-room .choose-space:hover {
  background-color: #555555;
}

.reservation-legend {
  margin: 25px 0;
}

@media (max-width: 768px) {
  .reservation-legend {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.reservation-legend .section-title, .reservation-legend .section-title-upper {
  margin-bottom: 0;
  margin-right: 20px;
  width: auto;
}

@media (max-width: 768px) {
  .reservation-legend .section-title, .reservation-legend .section-title-upper {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.reservation-legend .legend-item {
  margin-right: 15px;
  color: var(--superColorText);
}

@media (max-width: 768px) {
  .reservation-legend .legend-item {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .reservation-legend .legend {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.reservation-ticket {
  margin-bottom: 50px;
  margin-top: 27px;
}

.reservation-ticket .section-title, .reservation-ticket .section-title-upper {
  margin-bottom: 25px;
  color: var(--superColorText);
}

.reservation-ticket .ticket-item {
  margin-bottom: 15px;
  color: var(--superColorText);
}

.reservation-ticket__wrapper {
  width: 300px;
  max-width: 300px;
}

@media (max-width: 1315px) {
  .reservation-ticket__wrapper {
    width: 150px;
    max-width: 150px;
  }
}

@media (max-width: 1200px) {
  .reservation-ticket__wrapper {
    width: 300px;
    max-width: 300px;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .reservation-ticket__wrapper {
    width: 100%;
    max-width: 100%;
  }
}

.reservation-basket {
  background-color: var(--primaryColorLight);
  border: 1px solid var(--primaryColorLight2);
  padding: 15px 23px;
  color: var(--superColorText);
}

@media (max-width: 1315px) {
  .reservation-basket {
    padding: 10px;
  }
}

.reservation-basket .xs-span {
  margin-bottom: 5px;
}

@media (max-width: 1315px) {
  .reservation-basket .xs-span {
    font-size: 1.1rem;
  }
}

@media (max-width: 1200px) {
  .reservation-basket .xs-span {
    font-size: 1.4rem;
  }
}

.reservation-basket .choose-ticket {
  margin-bottom: 30px;
}

.reservation-basket .choose-ticket span {
  width: 130px;
}

.reservation-basket .choose-ticket svg {
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid #FF2727;
  border-radius: 50%;
  background-color: #fff;
  padding: 2px;
}

.reservation-basket .choose-ticket svg:hover {
  background-color: #FF2727;
}

.reservation-basket .choose-ticket svg:hover path {
  fill: #fff;
}

.reservation-basket .choose-ticket svg.is-active {
  background-color: #FF2727;
}

.reservation-basket .choose-ticket svg.is-active path {
  fill: #fff;
}

.reservation-basket .ticket-item {
  position: relative;
}

@media (max-width: 768px) {
  .reservation-basket .ticket-item svg {
    width: 18px;
    height: 18px;
  }
}

.reservation-basket .confirmRemove {
  right: 23px;
  bottom: 30px;
}

.baseSpace, .free-space, .full-space, .choose-space, .empty-space {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 12px;
  line-height: 12px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}

@media (max-width: 992px) {
  .baseSpace, .free-space, .full-space, .choose-space, .empty-space {
    width: 14px;
    height: 14px;
    font-size: 8px;
  }
}

@media (max-width: 768px) {
  .baseSpace, .free-space, .full-space, .choose-space, .empty-space {
    width: 13px;
    height: 13px;
  }
}

.free-space {
  background-color: #D2AB67;
}

.full-space {
  background-color: #EFEBE8;
}

.choose-space {
  background-color: #222222;
}

.empty-space {
  background-color: #fff;
}

.reservation-table .table {
  margin-bottom: 60px;
}

.reservation-table .table-item {
  position: relative;
  padding: 25px 0;
  border-bottom: 1px solid var(--primaryColorLight2);
  animation: fadeIn .3s;
}

@media (max-width: 768px) {
  .reservation-table .table-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.reservation-table .table-item:first-child {
  border-top: 1px solid var(--primaryColorLight2);
}

.reservation-table .table-item > div:not(.primary-btn):not(.remove-section) {
  width: 23%;
}

@media (max-width: 768px) {
  .reservation-table .table-item > div:not(.primary-btn):not(.remove-section) {
    width: 50%;
    margin-bottom: 20px;
  }
}

.reservation-table .table-item .text, .reservation-table .table-item .title {
  letter-spacing: 0.01em;
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--superColorText);
}

.reservation-table .table-item .title {
  opacity: .5;
}

.reservation-table .table-item .drop-down {
  width: 100%;
}

.reservation-table .table-item .drop-down .select-item {
  width: 100%;
}

.reservation-table .table-item .drop-down .select-item span {
  margin-right: 10px;
}

.reservation-table .table-item .drop-down .options ul {
  background-color: #fff;
  font-weight: normal;
}

.reservation-table .table-item .remove-section {
  margin-left: auto;
  position: relative;
  width: fit-content;
}

.reservation-table .table-item .remove-section .remove-reservation {
  margin-left: auto;
  width: fit-content;
}

.reservation-buttons {
  margin-bottom: 30px;
  position: relative;
}

.reservation-buttons .primary-btn-light {
  margin-right: 18px;
}

.reservation-buttons .g-recaptcha {
  position: absolute;
  left: 0;
  bottom: -50%;
}

.confirmRemove {
  position: absolute;
  right: -70%;
  bottom: 139%;
  background-color: #fff;
  border: 1px solid #D2AB67;
  padding: 15px;
  animation: fadeIn .3s;
}

@media (max-width: 768px) {
  .confirmRemove {
    right: 0;
  }
}

.confirmRemove--title {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 18px;
}

.confirmRemove--buttons .primary-btn {
  margin-left: 17px;
}

.confirmRemove:after {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  right: 122px;
  bottom: -6px;
  background-color: #fff;
  border: 1px solid #d2ab67;
  border-left: none;
  border-bottom: none;
  transform: rotate(134deg);
}

.timer {
  height: 30px;
  position: absolute;
  top: 34vh;
  width: 100%;
  z-index: 1;
  color: transparent;
  font-feature-settings: 'pnum' on,'lnum' on;
}

@media (max-width: 992px) {
  .timer {
    top: 29vh;
  }
}

@media (max-width: 768px) {
  .timer {
    top: 17vh;
  }
}

.timer__time {
  width: 50px;
  display: inline-block;
}

.timer .container {
  height: 100%;
  display: flex;
  align-items: center;
}

.timer.is-active {
  background-color: var(--primaryColor);
  color: var(--superColorBg);
  font-weight: 500;
}

.reservationPage--form {
  margin-bottom: 50px;
}

.reservationPage--form .section-title-upper {
  margin: 50px 0 10px 0;
}

.reservationPage--form form {
  margin-bottom: 50px;
}

.reservationPage--form form .input-group {
  position: relative;
  width: 100%;
}

.reservationPage--form form .input-group:not(:last-child) {
  margin-right: 70px;
}

.reservationPage--form form input:not(:last-child) {
  margin-right: 70px;
}

.reservationPage--form form .error-label {
  position: absolute;
  bottom: 3px;
  font-size: 1.1rem;
}

.reservationPage--form form .cbx {
  margin-top: 10px;
}

.custom-input {
  border: none;
  padding: 18px 0;
  border-bottom: 1px solid var(--superGrayLine);
  color: var(--superColorText);
  font-family: Raleway;
  width: 100%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  background-color: var(--superColorBg);
}

.custom-input:focus {
  outline: none;
}

.custom-input::placeholder {
  font-family: Raleway;
  color: var(--superGrayLine);
}

.reservationPage--basket {
  margin: 50px 0;
}

.reservationPage--basket .section-title-upper, .reservationPage--basket .section-title, .reservationPage--basket .section-title-upper {
  margin-bottom: 25px;
}

.reservationPage--basket .basket-user, .reservationPage--basket .basket-seats {
  margin-bottom: 25px;
}

.reservationPage--basket .basket-user .table, .reservationPage--basket .basket-seats .table {
  margin-bottom: 25px;
}

.reservationPage--basket .basket-user .table .reservationError, .reservationPage--basket .basket-seats .table .reservationError {
  font-weight: bold;
  color: #FF2727;
}

.reservationPage--basket .basket-user .table-item .title:nth-child(1) {
  width: 10%;
}

.reservationPage--basket .basket-user .table-item .title:nth-child(3) {
  width: 13%;
}

.reservationPage--basket .basket-user .table-item .title:nth-child(5) {
  width: 8%;
}

.reservationPage--basket .basket-user .table-item .text:nth-child(2), .reservationPage--basket .basket-user .reservation-table .table-item .title:nth-child(2), .reservation-table .reservationPage--basket .basket-user .table-item .title:nth-child(2) {
  width: 28%;
}

.reservationPage--basket .basket-user .table-item .text:nth-child(4), .reservationPage--basket .basket-user .reservation-table .table-item .title:nth-child(4), .reservation-table .reservationPage--basket .basket-user .table-item .title:nth-child(4) {
  width: 22%;
}

.reservationPage--basket .basket-user .table-item .text:nth-child(6), .reservationPage--basket .basket-user .reservation-table .table-item .title:nth-child(6), .reservation-table .reservationPage--basket .basket-user .table-item .title:nth-child(6) {
  width: 19%;
}

.reservationPage--basket .reservation-buttons {
  margin-top: 30px;
}

.reservationPage--pay {
  margin: 50px 0;
}

.reservationPage--pay .section-title-upper {
  margin-bottom: 40px;
}

.reservationPage--pay .pay-item {
  border: 1px solid var(--primaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--superColorText);
  width: 198px;
  height: 198px;
  font-weight: 600;
  font-feature-settings: 'pnum' on, 'lnum' on;
  cursor: pointer;
  font-size: 1.6rem;
}

.reservationPage--pay .pay-item:not(:last-child) {
  margin-right: 35px;
}

.reservationPage--pay .pay-item:hover {
  background-color: #EFEBE8;
}

.reservationPage--pay .pay-item.is-active {
  background-color: var(--primaryColor);
  color: var(--superColorBg);
}

.reservationPage--pay .reservation-buttons {
  margin-top: 40px;
}

.reservationPage--msg {
  min-height: 400px;
}

.reservationPage--msg .reservationFinish-svg {
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--superColorText);
  margin-bottom: 80px;
}

.reservationPage--msg .reservationFinish-svg svg {
  margin-right: 23px;
}

.reservationPage--msg .reservationFinish-svg svg path {
  fill: var(--primaryColor);
}

.reservationPage--msg .reservationFinish-svg svg circle {
  stroke: var(--primaryColor);
}

.drop-down {
  position: relative;
}

.drop-down .selected .select-item {
  border-bottom: 1px solid var(--superColorText);
  text-decoration: none;
  color: var(--superColorText);
  padding: 10px 0;
  font-size: 1.3rem;
  line-height: 137.5%;
  cursor: pointer;
}

.drop-down .selected .arrow {
  transition: 0.3s;
}

.drop-down .selected .arrow svg path {
  fill: var(--superColorText);
}

.drop-down .options ul {
  display: none;
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  min-width: 150px;
  font-size: 1.4rem;
  z-index: 1;
  animation: fadeIn .3s;
  border: 1px solid var(--primaryColor);
  border-top: none;
}

.drop-down .options li {
  padding: 7px;
  display: block;
  text-decoration: none;
  color: var(--superColorText);
  cursor: pointer;
}

.drop-down .options li label {
  cursor: pointer;
}

.drop-down .options li:hover {
  color: #D2AB67;
}

.drop-down .options li.is-active {
  color: #D2AB67;
  font-weight: 600;
}

.drop-menu {
  position: relative;
}

@media (max-width: 768px) {
  .drop-menu {
    background-color: var(--superColorBg);
  }
}

.drop-menu .breadcrumbs .share-element svg {
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1200px) {
  .drop-menu .breadcrumbs .share-element svg {
    margin-right: 10px;
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .drop-menu__content {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
  }
}

@media (max-width: 1200px) {
  .drop-menu__content.show {
    visibility: visible;
    opacity: 1;
    z-index: 2;
  }
}

@media (max-width: 768px) {
  .drop-menu__content.show {
    background-color: var(--superColorBg);
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
  .drop-menu__content .drop-menu__lists {
    width: 35%;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
    margin-top: 0;
    border: none;
  }
}

@media (max-width: 992px) {
  .drop-menu__content .drop-menu__lists {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .drop-menu__content .drop-menu__lists {
    width: 100vw;
    max-width: 540px;
    margin: 0 auto;
    height: 100vh;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--superColorBg);
  }
}

@media (max-width: 576px) {
  .drop-menu__content .drop-menu__lists {
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
  .drop-menu__content .drop-menu__lists .drop-menu__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--primaryColor);
    width: 100%;
    margin: 0;
    padding: 0 20px 20px;
  }
}

.drop-menu__content .drop-menu__lists .drop-menu__header .drop-menu-close {
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1200px) {
  .drop-menu__content .drop-menu__lists .drop-menu__header .drop-menu-close {
    text-transform: capitalize;
  }
}

@media (max-width: 1200px) {
  .drop-menu__content .drop-menu__lists .drop-menu__header .drop-menu-close svg {
    margin-right: 10px;
  }
}

.drop-menu__content .drop-menu__lists .drop-menu__header .drop-menu-close svg path {
  fill: var(--superColorText);
  transition: all 0.3s ease-in-out;
}

.drop-menu__content .drop-menu__lists .drop-menu__header .drop-menu-close svg path:hover {
  fill: var(--primaryColor2);
}

.drop-menu__content .drop-menu__lists .drop-menu__header .drop-menu-close:hover svg path {
  fill: var(--primaryColor2);
}

.drop-menu-close, .drop-menu-show {
  display: none;
  color: var(--superColorText);
  transition: all 0.3s ease-in-out;
}

.drop-menu-close:hover, .drop-menu-show:hover {
  color: var(--primaryColor);
}

@media (max-width: 1200px) {
  .drop-menu-close, .drop-menu-show {
    display: block;
    cursor: pointer;
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .drop-menu-close, .drop-menu-show {
    font-size: 1.1rem;
  }
}

.hamburger-menu {
  display: none;
}

@media (max-width: 992px) {
  .hamburger-menu {
    display: inline-block;
    padding: 10px 0;
  }
}

@media (max-width: 768px) {
  .hamburger-menu {
    padding: 7px 0;
  }
}

.hamburger-menu p {
  color: var(--primaryColor);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.4rem;
  cursor: pointer;
  text-align: right;
}

@media (max-width: 768px) {
  .hamburger-menu p {
    font-size: 1.1rem;
    margin-top: 6px;
  }
}

.hamburger-menu svg {
  width: 40px;
  height: 40px;
  fill: var(--superColorBg2);
}

@media (max-width: 768px) {
  .hamburger-menu svg {
    width: 30px;
    height: 22px;
  }
}

.hamburger-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.hamburger-icon .menu-icon {
  stroke: var(--primaryColor);
}

.left-menu__item {
  font-size: 1.4rem;
  line-height: 16px;
  padding: 13px 25px 0 25px;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  position: relative;
}

.left-menu__item .item__arrow {
  top: 0;
  right: 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.left-menu__item .item__arrow svg {
  transition: all 0.3s ease-in-out;
}

.left-menu__item .item__arrow svg path {
  fill: var(--superColorText);
  stroke: var(--superColorText);
}

.left-menu__item .item__arrow.is-active svg {
  transform: rotate(180deg);
}

.left-menu__item .item__arrow:hover svg path {
  fill: var(--primaryColor);
  stroke: var(--primaryColor);
}

.left-menu__item .item__header.is-active a {
  color: var(--primaryColor);
  border-bottom: 1px solid var(--primaryColor);
}

.left-menu__item .item__header.is-active svg path {
  fill: var(--primaryColor);
  stroke: var(--primaryColor);
}

.left-menu__item li {
  transition: all 0.3s ease-in-out;
}

.left-menu__item li:not(:last-child) {
  margin-bottom: 10px;
}

.left-menu__item li a {
  font-weight: 500;
  width: 100%;
}

.left-menu__item li a.is-active {
  color: var(--primaryColor);
  display: inline;
}

.left-menu__item a {
  color: var(--superColorText);
  text-decoration: none;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.left-menu__item a:hover {
  color: #D2AB67;
}

.left-menu__item a.is-active {
  color: var(--primaryColor);
  border-bottom: 1px solid var(--primaryColor);
}

.upTo-arrow {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: var(--primaryColor);
  bottom: 50px;
  right: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
  animation: fadeIn .4s;
}

.upTo-arrow svg {
  fill: var(--superColorBg);
  width: 20px;
  height: 15px;
  transform: rotate(180deg);
}

.upTo-arrow:hover {
  background-color: #a37930;
}

.container-login {
  border: 1px solid var(--primaryColor);
  width: 40%;
  margin: 70px auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1200px) {
  .container-login {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .container-login {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container-login {
    width: 100%;
    padding: 30px;
  }
}

.container-login .login__header {
  font-weight: 500;
  font-size: 2rem;
  line-height: 26px;
  color: var(--superColorText);
  text-align: center;
  margin-bottom: 50px;
}

.container-login .login__input {
  width: 50%;
  position: relative;
}

@media (max-width: 1200px) {
  .container-login .login__input {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .container-login .login__input {
    width: 90%;
  }
}

.container-login .login__input .error-label {
  font-size: 1.1rem;
  bottom: 0;
  position: absolute;
  left: 0;
}

.container-login .login__error {
  color: #FF2727;
  margin-top: 10px;
  font-size: 1.4rem;
}

.container-login .login__button {
  margin-top: 50px;
}

html,
body {
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

html.overflow-y {
  overflow-y: hidden;
}

*,
::after,
::before {
  box-sizing: inherit;
}

body {
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  body {
    font-size: 1.3rem;
  }
}

.d-none {
  display: none;
}

.full-width {
  width: 100%;
}

.blurScreen {
  filter: blur(2px);
}

.primary-text {
  color: #D2AB67;
}

.error-label {
  color: #FF2727;
  font-weight: 500;
  margin-top: 5px;
}

.ml-auto {
  margin-left: auto;
}

.width-30 {
  width: 30%;
}

.note-float-left {
  float: left;
  padding: 10px 10px 10px 0;
}

@media (max-width: 768px) {
  .note-float-left {
    padding: 10px;
  }
}

.note-float-right {
  float: right;
  padding: 10px 0 10px 10px;
  max-width: 290px !important;
}

@media (max-width: 768px) {
  .note-float-right {
    padding: 10px 0;
  }
}

p {
  line-height: 156.5%;
  color: var(--superColorText);
}

@media (max-width: 768px) {
  p {
    line-height: 166.5%;
  }
}

.rotate_180 {
  transform: rotate(180deg);
}

.staticPages .note-float-right {
  width: auto;
  height: auto;
}

.staticPages .swiper-container {
  width: 49%;
  float: right;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .staticPages .swiper-container {
    width: 100%;
    margin-left: 0;
  }
}

.staticPages .swiper-container .swiper-button-next,
.staticPages .swiper-container .swiper-button-prev {
  bottom: -2px;
  top: unset;
}

.staticPages .swiper-container .swiper-button-next:after,
.staticPages .swiper-container .swiper-button-prev:after {
  font-size: 13px;
}

.staticPages .swiper-container .swiper-wrapper {
  height: 88%;
}

.staticPages .swiper-container .swiper-button-prev {
  right: 58%;
  left: unset;
}

.staticPages .swiper-container .swiper-button-next {
  left: 58%;
}

.staticPages .swiper-container .swiper-slide {
  width: 100% !important;
}

.staticPages .swiper-container .swiper-lightbox {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .staticPages .section-title-upper {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .staticPages {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .staticPages img {
    padding: 0 0 20px;
    width: 100% !important;
  }
}

b,
strong {
  font-weight: 600;
}

.email-toggle-wrapper {
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.email-toggle-wrapper .defaultShow {
  margin: 0 !important;
}

.email-toggle-wrapper .btn-show-email {
  background: transparent;
  border: 0;
  padding: 0;
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 767px) {
  img {
    max-width: 100% !important;
    height: auto !important;
  }
}

.page-185 table tr, .page-186 table tr, .page-187 table tr, .page-188 table tr {
  align-items: center;
}

@media (max-width: 768px) {
  .page-373 table tr {
    flex-direction: column;
  }
}

.page-373 table tr td + td {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .page-373 table tr td + td {
    margin-left: 0;
    margin-top: 20px;
  }
}

.page-373 table tr td ul li {
  font-weight: 400;
}

.page-373 table tr td ul li a {
  font-size: 1.3rem;
  line-height: 24px;
}

[container-type="cycles"] .section-staff--info .xs-span p, [container-type="cycles"] .section-staff--info .xs-span span {
  color: var(--superColorText) !important;
}

[container-type="attemptPlan"] .section-title-upper {
  margin-bottom: 20px;
}

[container-type="attemptPlan"] .block-item .section-title, [container-type="attemptPlan"] .block-item .section-title-upper {
  transition: all 0.3s ease-in-out;
}

[container-type="attemptPlan"] .block-item:hover .section-title, [container-type="attemptPlan"] .block-item:hover .section-title-upper {
  color: #D2AB67;
}

.archive-link {
  margin: 40px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.archive-link a {
  text-decoration: none;
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 1.5rem;
  width: 100%;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.archive-link a:hover {
  color: var(--superGray);
}

.lightbox .lb-prev {
  background: url(../assets/images/lightbox/prev.png) left 48% no-repeat !important;
}

.lightbox .lb-next {
  background: url(../assets/images/lightbox/next.png) right 48% no-repeat !important;
}

.lightbox .lb-close {
  background: url(../assets/images/lightbox/close.png) top right no-repeat !important;
}
