.section-staff{
  margin: 20px 0 0 20px;
  @include respond-to('l') {
    margin: 20px 0;
  }

  .section-title-upper{
    margin-bottom: 20px;
  }
}

.staff-photos{
  display: grid;
  grid-template-columns: repeat(3, calc(100%/3 - 15px));
  grid-gap: 20px;
  align-items: baseline;

  .person-item{
    cursor: pointer;

    &:hover{
      h2{
        color: $primary-color;
        transition: $transition-base;
      }
    }
  }

  img{
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

  h2{
    padding-top: 10px;
    color: var(--superColorText);

    @include respond-to('s') {
      min-height: 35px;
    }
    &:last-child{
      padding-bottom: 10px;
    }
  }

  .staff-name{
    font-weight: 600;
  }
}

[container-type="about-list"] {
  .staff-photos {
    img {
      @include respond-to('xs') {
        height: 233px;
      }
    }
    @include respond-to('m') {
      grid-template-columns: repeat(3, calc(100%/3 - 5px));
      grid-gap: 9px;
    }
    @include respond-to('s') {
      grid-template-columns: repeat(2, calc(100%/2 - 5px));
      grid-gap: 9px;
    }
  }
}