.main-section-img{
  height: 40vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom !important;
  background-size: cover;
  @include respond-to('s') {
    height: 34vh;
  }
  &.small{
    height: 20vh;
    @include respond-to('s') {
      height: 87px;
    }
  }
}
.gallery-banner {
  height: 40vh !important;
  @include respond-to('s') {
    height: 34vh !important;
  }
}

.section-sponsor{ //.section-partner,
  .section-title{
    margin: 20px 0;
  }
}
//Change styles in section news - rwd
.section-news {
  .to-flex {
    width: 100%;
  }
  .left-container {
    @include respond-to('xl') {
      flex: 1;
      width: 100%;
    }
    .container-position {
      @include respond-to('s') {
        background-color: var(--superColorBg);
        border: none;
        margin-top: 0;
      }
      .downloadFile {
        @include respond-to('s') {
          display: none;
        }
      }
    }
    .primary-btn {
      margin-bottom: 20px;
      @include respond-to('s') {
        width: 100%;
      }
    }
  }
  .news-block {
    @include respond-to('xl') {
      grid-template-columns: repeat(2,calc(100% / 2 - 15px));
    }
    @include respond-to('m') {
      grid-template-columns: repeat(1,calc(100%));
    }
    @include respond-to('s') {
      grid-gap: 31px;
      margin-top: 20px;
      grid-template-columns: repeat(2,calc(100% / 2 - 15px));
    }
    @include respond-to('xs') {
      grid-template-columns: repeat(1,calc(100%));
    }
  }
  // Styles content for other events - rwd
  .news-other-event {
    .section-title-upper {
      @include respond-to('s') {
        margin: 67px 0 0;
      }
    }
    .news-block {
      @include respond-to('m') {
        grid-template-columns: repeat(3,calc(100%/3 - 15px));
      }
      @include respond-to('s') {
        grid-template-columns: repeat(2,calc(100%/2 - 15px));
        margin-top: 25px;
      }
      @include respond-to('xs') {
        grid-template-columns: 100%;
      }
    }
  }
  .to-flex {
    @include respond-to('s') {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  .right-container {
    @include respond-to('s') {
      width: 100%;
      margin: 20px 0;
    }
  }
  .news-container {
    .goToLink {
      @include respond-to('m') {
        display: none;
      }
    }
    .section-title-upper {
      @include respond-to('m') {
        text-align: center;
      }
    }
  }
}

//Change style in section sponsors&partners - rwd
.sponsors {
  .to-flex-ai {
    @include respond-to('s') {
      grid-template-columns: 100%;
    }
  }
}
.sponsors__row{
  display: grid;
  grid-template-columns: 36% 20% 36%;
  justify-content: space-between;
  margin: 20px 0;

  >div{
    height: 100%;
  }
}
.section-picture{
  margin: auto;
}

.section-sponsor{
  display: flex;
  flex-direction: column;
  .swiper-container {
    width: 450px;
    height: 100px;

    img{
      width: inherit;
      height: inherit;
      object-fit: contain;
    }
  }
}

.twoTest{

}

.twoLogo{
  img{
    width: 50%;
    object-fit: contain;
  }
}

.oneLogo{
  img{
    width: 100%;
    object-fit: contain;
  }
}

//.section-partner{
//  width: 70%;
//  @include respond-to('m') {
//    flex: 1;
//  }
//  @include respond-to('s') {
//    width: 100%;
//  }
//  .section-picture{
//    justify-content: space-between;
//    display: flex;
//    flex-wrap: wrap;
//    border-right: 1px solid $primary-color;
//    padding-right: 40px;
//    @include respond-to('m') {
//      justify-content: flex-start;
//      padding-right: 0;
//    }
//    @include respond-to('s') {
//      border-right: none;
//    }
//    img {
//      @include respond-to('m') {
//        width: calc((100% / 2) - 20px);
//        height: 62px;
//        margin-right: 20px;
//      }
//      & + img {
//        @include respond-to('m') {
//          margin-right: 50px;
//        }
//        @include respond-to('m') {
//          margin-right: 0;
//        }
//      }
//    }
//  }
//}

//.section-sponsor{
//  width: 30%;
//  @include respond-to('m') {
//    width: 35%;
//  }
//  @include respond-to('s') {
//    width: 100%;
//  }
//
//  .section-title{
//    margin-left: 50px;
//    @include respond-to('s') {
//      margin-left: 0;
//    }
//  }
//
//  .swiper-container {
//    width: 300px;
//    height: 100px;
//  }
//}

.goToLink{
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  color: var(--superColorText);
  transition: $transition-base;

  .icon {
    margin-left: 10px;

    svg path {
      fill: var(--superColorText);
    }
  }

  &:hover{
    color: $primary-color;
    .icon {
      animation: .8s animationArrow infinite;
      svg {
        path {
          fill: $primary-color;
        }
      }
    }
  }
}
.left-container{
  height: fit-content;
  position: relative;

  .container-position{
    width: 100%;
    //margin-top: -40px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primaryColorLight);
    border: 1px solid var(--primaryColorLight2);
  }

  .primary-btn{
    width: 90%;
    padding: 15px 0;
  }

  .address-container{
    //position: absolute;
    //top: 330px;
    width: 100%;
    @include respond-to('s') {
      margin-top: 25px;
    }

    span{
      color: var(--superColorText);
    }

    .section-title-upper{
      padding: 15px 0;
      border-bottom: 1px solid var(--primaryColor);
      margin-bottom: 7px;
    }

    .address-line{
      padding: 5px 0;
      font-feature-settings: 'pnum' on,'lnum' on;

      .address-name{
        width: 20%;
      }

      .second-name{
        width: 80%;
      }

      a{
        text-decoration: none;
        color: var(--superColorText);
        transition: $transition-base;

        &:hover{
          color: $primary-color;
        }
      }
    }

    .address-data{
      margin-bottom: 15px;
      font-weight: 500;
      @include respond-to('s') {
        margin-bottom: 25px;
      }
    }
  }
}

.calendar-tooltip{
  position: absolute;
  top: 0;
  left: 135%;
  z-index: 99999;
  padding: 17px;
  //background-color: rgba(210,171,103,.9);
  background-color: var(--primaryColor2);
  border: 1px solid var(--primaryColor);
  cursor: default;
  @include respond-to('m') {
    left: 104%;
  }
  @include respond-to('s') {
    left: 0;
    width: 100%;
  }

  .tooltip-item{
    @include respond-to('s') {
      flex-direction: row;
    }
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }

  .tooltip-image{
    width: 102px;
    height: 85px;
    background: center no-repeat;
    background-size: cover;
    margin-right: 17px;
  }

  .tooltip-content{
    color: $white-color;
    width: 300px;
    display: flex;
    flex-direction: column;
    animation: fadeInText .5s;
    @include respond-to('s') {
      flex: 1;
    }

    .tooltip-header{
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 132.5%;
    }
    .tooltip-title{
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .tooltip-text{
      width: 100%;
      font-weight: normal;
      -webkit-line-clamp: 2;
    }

    &:hover{
      opacity: 0.6;
      transition: $transition-base;
    }
  }
  &:after{
    content: '';
    backdrop-filter: blur(2px);
    background-color: var(--primaryColor2);
    opacity: 0.9;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    position: absolute;
    top: -1px;
    left: -10.5px;
    width: 10px;
    height: 10px;
    @include respond-to('s') {
      display: none;
    }
  }
  &__close {
    display: none;
    @include respond-to('s') {
      display: block;
      padding: 0 0 30px;
      text-align: right;
    }
    svg path {
      @include respond-to('s') {
        fill: var(--superColorBg2);
      }
    }
  }
}


