.repertoire-section {
  margin: 20px 0;

  .downloadFile {
    padding: 0;
    font-weight: 600;
    @include respond-to('m') {
      display: none;
    }
  }
  .section-title-upper {
    @include respond-to('m') {
      text-align: left;
    }
  }

  .repHead {
    margin-top: 10px;
    padding: 20px 30px;
    background-color: var(--superGrayBlack);
    border: 1px solid var(--superGrayYellow);

    display: grid;
    grid-template-columns: 25% 15% 17% 15% 15%;
    justify-content: space-between;

    @include respond-to('m') {
      grid-template-columns: 100%;
    }

    .drop-down{
      @include respond-to('m') {
        padding: 10px 0;
      }
      @include respond-to('s') {
        max-width: 100%;
        width: 100%;
      }
      .select-item {
        @include respond-to('s') {
          width: 100%;
        }
      }
      &:first-child {
        margin-left: 20px;
        @include respond-to('l') {
          margin-left: 0;
        }
      }
      .options ul{
        background-color: var(--superGrayBlack);
        @include respond-to('m') {
          top: 55px;
        }
      }
    }
    .filter-btn {
      padding: 13px 27px;
      @include respond-to('l') {
        margin-right: 0;
        //margin-top: 25px;
      }
      @include respond-to('m') {
        margin-top: 0;
      }
      @include respond-to('s') {
        width: 100%;
        margin-top: 10px;
      }
    }

    #filterMonth {
      border: none;
      background-color: transparent;
      font-size: 1.3rem;
      color: var(--superColorText);
      font-family: Raleway;
      width: 100%;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 1.3rem;
        color: var(--superColorText);
        line-height: 137.5%;
        font-family: Raleway;
      }
    }

    .filter-search{
      @include respond-to('m') {
        padding: 10px 0;
      }
    }

    #search-input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid var(--superColorText);
      width: 100%;
      text-decoration: none;
      color: var(--superColorText);
      padding: 10px 0;
      font-size: 1.3rem;
      line-height: 137.5%;
      font-family: Raleway;

      &::placeholder{
        color: var(--superColorText);
        font-family: Raleway;

      }

      &:focus{
        outline: none;
      }

      @include respond-to('m') {
        width: 100%;
      }
    }

  }

  .repContent {
    position: relative;
    min-height: 100px;
  }

}

.repRow {
  padding: 20px 0;
  &:hover {
    cursor: default;
  }
  .block-content {
    align-items: flex-start;
    @include respond-to('m') {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  .repRow-img {
    img {
      height: 144px;
      @include respond-to('s') {
        width: 100%;
      }
      @include respond-to('xs') {
        height: 96px;
      }
    }
    @include respond-to('s') {
      width: 50%;
    }
  }

  .repRow-calendar {
    background-color: var(--superGrayBlack);
    border: 1px solid var(--superGrayYellow);
    color: var(--superColorText);
    margin-right: 16px;
    width: 158px;
    height: 144px;
    @include respond-to('m') {
      display: none;
    }

    .calendar-date {
      font-size: 18px;
      line-height: 137%;
    }

    .calendar-date-big {
      font-size: 3.8rem;
      line-height: 137%;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
    .calendar-hour {
      font-size: 1.4rem;
      line-height: 137%;
    }
  }

  .repRow-text {
      flex: 1;
    @include respond-to('s') {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
    }
    .repRow-header {
      @include respond-to('s') {
        width: 50%;
      }
    }
    .repRow-title {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 132.5%;
    }
    .repRow-data-mobile {
      display: none;
      @include respond-to('m') {
        display: block;
        font-size: 1.4rem;
      }
      @include respond-to('s') {
        margin-top: 10px;
      }
    }
    .repRow-description {
      margin-top: 15px;
      font-size: 1.4rem;
      line-height: 137.5%;
      @include respond-to('s') {
        font-size: 1.3rem;
        width: 100%;
      }

      .cut-text {
        // error - when description is too long for container
        //white-space: pre;
      }
    }

    .repRow-signature {
      font-size: 14px;
      line-height: 137.5%;
    }
  }

  .btn-s {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    @include respond-to('m') {
      display: flex;
      flex-direction: row;
      margin: 20px 0 0 auto;
    }
    @include respond-to('s') {
      width: 100%;
      max-width: 100%;
    }

    .repRow-btn{
      font-size: 1.2rem;
      width: 145px;
      height: 38px;
      padding: 0;
      line-height: 14px;
      @include respond-to('s') {
        width: 50%;
      }
    }

    .repRow-btn1 {
      @extend .repRow-btn;
      margin-bottom: 8px;
      @include respond-to('m') {
        margin-bottom: 0;
      }
    }

    .repRow-btn2 {
      @extend .repRow-btn;
      margin-top: 7px;
      @include respond-to('m') {
        margin-top: 0;
        margin-left: 20px;
      }
      @include respond-to('s') {
        margin-left: 7px;
      }
    }
  }

}


