.newsletter {
  .container {
    @include respond-to('s') {
      max-width: 100%;
      padding: 0;
      background-color: var(--primaryColorLight);
    }
  }
}
.section-newsletter{
  background-color: var(--primaryColorLight);
  padding: 30px;
  margin-top: 30px;
  border: 1px solid var(--primaryColorLight2);
  @include respond-to('s') {
    max-width: 540px;
    margin: 30px auto 0;
    padding: 20px 10px 20px;
  }

  .section-title-upper{
    min-height: 40px;
    display: flex;
    align-items: center;
  }

  .newsletter-block{

    @include respond-to('s') {
      flex-direction: column;
      margin-top: 0;
    }

    > *{
      width: 48%;
      @include respond-to('s') {
        width: 100%;
      }
    }

    .block--content{
      line-height: 190.5%;
      font-weight: 500;
      color: var(--superColorText);
      @include respond-to('s') {
        font-size: 13px;
      }
    }

    .block-signUP{
      position: relative;
      @include respond-to('s') {
        padding-top: 28px;
      }
    }

    .sendedMail{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-light-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: $text-color;

      svg {
        margin-right: 10px;
      }
    }

    .newsletter-mail{
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @include respond-to('m') {
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-bottom: 21px;
      }
    }

    .newsletter-input{
      border: 0;
      background-color: transparent;
      border-bottom: 1px solid var(--primaryColor);
      width: 65%;
      padding: 10px 0;
      font-family: Raleway, serif;
      @include respond-to('l') {
        margin-bottom: 10px;
      }
      @include respond-to('m') {
        margin-right: 13px;
      }

      &:focus, &:active{
        outline: none;
      }

      &::placeholder{
        color: var(--superColorText);
      }

    }
    .newsletter-btn {
      @include respond-to('l') {
        margin-bottom: 10px;
      }
      @include respond-to('m') {
        width: 118px;
        max-width: 118px;
        padding: 12px 6px;
      }
    }
    &--auth-cbx {
      .cbx {
        input[type=checkbox]+label:before {
          top: unset;
        }
        label {
          @include respond-to('l') {
            display: block;
          }
        }
      }
    }
  }
  .cbx {
    .xs13-span {
      transition: $transition-base;
      &:hover {
        color: $primary-color;
      }
    }
  }
}