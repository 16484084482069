.upTo-arrow{
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: var(--primaryColor);
  bottom: 50px;
  right: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
  animation: fadeIn .4s;

  svg{
    fill: var(--superColorBg);
    width: 20px;
    height: 15px;
    transform: rotate(180deg);
  }

  &:hover{
    background-color: darken($primary-color, 20%);
  }
}