.load-container {
  width: 100%;
  // height: calc(100% - 80px);
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 99999;
  background-color: var(--superColorBg);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}