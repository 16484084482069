.breadcrumbs{
  margin-bottom: 40px;
  @include respond-to('s') {
    margin-bottom: 26px;
  }

  >span{
    position: relative;
    //max-width: 100px;
    line-height: 16px;
    color: var(--superColorText);

    &:first-child{
      font-weight: 500;
    }

    &:not(:first-child){
      color: var(--superColorText);
    }
  }

  &-space{
    top: 7px;
    width: 13px;
    height: 1px;
    background-color: var(--superColorText);
    left: 118%;
    margin: 0 10px;
  }
  a{
    text-decoration: none;
    color: var(--superColorText);
    transition: $transition-base;
    &:hover{
      color: var(--primaryColor2);
    }
  }
  .share-element{
    margin-left: auto;
    color: var(--superColorText);
    text-decoration: none;
    transition: $transition-base;
    @include respond-to('s') {
      width: 60%;
      text-align: right;
    }
    svg{
      margin-left: 10px;
      path{
        transition: $transition-base;
        fill: var(--superColorText);
      }
    }

    a{
      text-decoration: none;
      color: var(--superColorText);
      transition: $transition-base;
    }

    &:hover{

      a{
        color: var(--primaryColor2);
      }
      svg path {
        fill: var(--primaryColor2);
      }
    }
  }
  .cut-text {
    @include respond-to('m') {
      font-size: 1.1rem;
    }
    @media (max-width: 320px) {
      font-size: 0.9rem;
    }
  }
  .drop-menu-show {
    width: 150px;
    margin-left: auto;
    text-align: right;
    @include respond-to('s') {
      width: 79px;
    }
  }
}
