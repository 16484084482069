.reservationPage--form{
  margin-bottom: 50px;

  .section-title-upper{
    margin: 50px 0 10px 0;
  }

  form{
    margin-bottom: 50px;

    .input-group{
      position: relative;
      width: 100%;

      &:not(:last-child){
        margin-right: 70px;
      }
    }

    input:not(:last-child){
      margin-right: 70px;
    }

    .error-label{
      position: absolute;
      bottom: 3px;
      font-size: 1.1rem;
    }

    .cbx{
      margin-top: 10px;
    }
  }

}

.custom-input{
  border: none;
  padding: 18px 0;
  border-bottom: 1px solid var(--superGrayLine);
  color: var(--superColorText);
  font-family: Raleway;
  width: 100%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  background-color: var(--superColorBg);

  &:focus{
    outline: none;
  }

  &::placeholder{
    //color: $light-line;
    font-family: Raleway;
    color: var(--superGrayLine);
  }
}