.reservationPage{
  margin: 50px 0;
  width: 100%;

  .section-title-upper, .section-title{
    margin-bottom: 20px;
  }
}

.reservation-seats{
  margin-bottom: 40px;
  align-items: start;
  @include respond-to('l') {
    flex-direction: column;
  }
}

.reservation-wrapper{
  width: 920px;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin-right: 20px;
  //@include respond-to('l') {
  //  margin-right: 0;
  //  width: 100%;
  //}

  //max-height: 820px;
}

.reservation-zoom{
  margin-bottom: 20px;

  @include respond-to('s') {
    display: flex;
    justify-content: space-between;
  }
  i {
    margin-left: 5px;
  }
  div{
    margin-right: 10px;
    @include respond-to('s') {
      width: calc((100% / 3) - 5px);
      margin-right: 0;
    }
  }
}

.reservation-room{
  margin: 0 30px;
  display: inline-flex;
  //zoom: 1.3;
  @include respond-to('s') {
    zoom: 0.7;
  }
  @include respond-to('xs') {
    zoom: 0.5;
  }

  .right-side{
    margin-top: 50px;
    margin-left: 40px;

    .space{
      margin-right: 6px;
      margin-bottom: 10px;
    }
  }

  .section-mini-title{
    margin-bottom: 11px;
    color: var(--superColorText);
  }

  .reservation-row--space{
    position: relative;
    width: fit-content;

    .space{
      margin-right: 6px;
      margin-bottom: 10px;
    }

    &:before{
      content: attr(row-number);
      position: absolute;
      width: 30px;
      color: var(--superColorText);;
      left: -40px;
      top: 0;
      text-align: end;
    }

    &:after{
      content: attr(row-number);
      position: absolute;
      width: 30px;
      color: var(--superColorText);
      right: -50px;
      top: 0;
    }

    &.plus-padding{
      padding-left: 10px;

      &:after{
        right: -40px;
      }
    }
  }

  .balcony{
    margin-top: 23px;
  }

  .free-space{
    cursor: pointer;

    &:hover{
      background-color: darken($primary-color, 20%);
    }
  }

  .choose-space{
    cursor: pointer;

    &:hover{
      background-color: lighten($text-color, 20%);
    }
  }
}

.reservation-legend{
  margin: 25px 0;
  @include respond-to('s') {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .section-title{
    margin-bottom: 0;
    margin-right: 20px;
    width: auto;
    @include respond-to('s'){
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .legend-item{
    margin-right: 15px;
    color: var(--superColorText);
    @include respond-to('s') {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .legend {
    @include respond-to('s') {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.reservation-ticket{
  margin-bottom: 50px;
  margin-top: 27px;

  .section-title{
    margin-bottom: 25px;
    color: var(--superColorText);
  }

  .ticket-item{
    margin-bottom: 15px;
    color: var(--superColorText);
  }
  &__wrapper {
    width: 300px;
    max-width: 300px;
    @include respond-to('xl') {
      width: 150px;
      max-width: 150px;
    }
    @include respond-to('l') {
      width: 300px;
      max-width: 300px;
      margin-top: 30px;
    }
    @include respond-to('s') {
      width: 100%;
      max-width: 100%;
    }
  }
}

.reservation-basket{
  background-color: var(--primaryColorLight);
  border: 1px solid var(--primaryColorLight2);
  padding: 15px 23px;
  //min-width: 300px;
  color: var(--superColorText);
  //width: 100%;
  @include respond-to('xl') {
    padding: 10px;
  }
  .xs-span{
    margin-bottom: 5px;
    @include respond-to('xl') {
      font-size: 1.1rem;
    }
    @include respond-to('l') {
      font-size: 1.4rem;
    }
  }

  .choose-ticket{
    margin-bottom: 30px;

    span{
      width: 130px;
    }

    svg{
      margin-left: 10px;
      cursor: pointer;
      border: 1px solid $error-color;
      border-radius: 50%;
      background-color: $white-color;
      padding: 2px;

      &:hover{
        background-color: $error-color;
        path{
          fill: $white-color;
        }
      }

      &.is-active{
        background-color: $error-color;
        path{
          fill: $white-color;
        }
      }
    }
  }

  .ticket-item{
    position: relative;
    svg {
      @include respond-to('s') {
        width: 18px;
        height: 18px;
      }
    }
  }

  .confirmRemove{
    right: 23px;
    bottom: 30px;
  }
}

.baseSpace{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  //display: block;
  margin-right: 10px;

  font-size: 12px;
  line-height: 12px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  @include respond-to('m') {
    width: 14px;
    height: 14px;
    font-size: 8px;
  }
  @include respond-to('s') {
    width: 13px;
    height: 13px;
  }
}

.free-space{
  @extend .baseSpace;
  background-color: $primary-color;
}
.full-space{
  @extend .baseSpace;
  background-color: $primary-light-color;
}
.choose-space{
  @extend .baseSpace;
  background-color: $text-color;
}
.empty-space{
  @extend .baseSpace;
  background-color: $white-color;
}

.reservation-table{
  .table{
    margin-bottom: 60px;
  }

  .table-item{
    position: relative;
    padding: 25px 0;
    border-bottom: 1px solid var(--primaryColorLight2);
    animation: fadeIn .3s;
    @include respond-to('s') {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &:first-child{
      border-top: 1px solid var(--primaryColorLight2);
    }

    & > div:not(.primary-btn):not(.remove-section){
      width: 23%;
      @include respond-to('s') {
        width: 50%;
        margin-bottom: 20px;
      }
    }

    .text{
      letter-spacing: 0.01em;
      font-weight: 600;
      font-size: 1.5rem;
      color: var(--superColorText);
    }

    .title{
      @extend .text;
      opacity: .5;
    }

    .drop-down{
      //width: fit-content;
      width: 100%;

      .select-item{
        width: 100%;

        span{
          margin-right: 10px;
        }
      }

      .options ul{
        background-color: $white-color;
        font-weight: normal;
      }
    }

    .remove-section{
      margin-left: auto;
      position: relative;
      width: fit-content;

      .remove-reservation{
        margin-left: auto;
        width: fit-content;
      }
    }

  }
}

.reservation-buttons{
  margin-bottom: 30px;
  position: relative;

  .primary-btn-light{
    margin-right: 18px;
  }

  .g-recaptcha{
    position: absolute;
    left: 0;
    bottom: -50%;
  }
}
.confirmRemove{
  position: absolute;
  right: -70%;
  bottom: 139%;
  background-color: $white-color;
  border: 1px solid $primary-color;
  padding: 15px;
  animation: fadeIn .3s;
  @include respond-to('s') {
    right: 0;
  }
  &--title{
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 18px;
  }
  &--buttons{
    .primary-btn{
      margin-left: 17px;
    }
  }
  &:after{
    content: '';
    //clip-path: polygon(100% 0,0 0,100% 100%);
    width: 10px;
    height: 10px;
    position: absolute;
    right: 122px;
    bottom: -6px;
    background-color: $white-color;
    border: 1px solid #d2ab67;
    border-left: none;
    border-bottom: none;
    transform: rotate(134deg);
  }
}

.timer{
  height: 30px;
  position: absolute;
  top: 34vh;
  width: 100%;
  z-index: 1;
  color: transparent;
  font-feature-settings: 'pnum' on,'lnum' on;

  @include respond-to('m') {
    top: 29vh;
  }
  @include respond-to('s') {
    top: 17vh;
  }

  &__time{
    width: 50px;
    display: inline-block;
  }

  .container{
    height: 100%;
    display: flex;
    align-items: center;
  }

  &.is-active{
    background-color: var(--primaryColor);
    color: var(--superColorBg);
    font-weight: 500;
  }
}