.container-login{
  border: 1px solid var(--primaryColor);
  width: 40%;
  margin: 70px auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-to('l') {
    width: 50%;
  }
  @include respond-to('m') {
    width: 100%;
  }
  @include respond-to('s') {
    width: 100%;
    padding: 30px;
  }

  .login{
    &__header{
      font-weight: 500;
      font-size: 2rem;
      line-height: 26px;
      color: var(--superColorText);
      text-align: center;
      margin-bottom: 50px;
    }

    &__input{
      width: 50%;
      position: relative;
      @include respond-to('l') {
        width: 70%;
      }
      @include respond-to('s') {
        width: 90%;
      }

      .error-label{
        font-size: 1.1rem;
        bottom: 0;
        position: absolute;
        left: 0;
      }
    }

    &__error{
      color: $error-color;
      margin-top: 10px;
      font-size: 1.4rem;
    }

    &__button{
      margin-top: 50px;
    }
  }
}