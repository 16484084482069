.reservationPage--basket{
  margin: 50px 0;
  .section-title-upper, .section-title{
    margin-bottom: 25px;
  }

  .basket-user, .basket-seats{
    margin-bottom: 25px;

    .table{
      margin-bottom: 25px;

      .reservationError{
        font-weight: bold;
        color: $error-color;
      }
    }
  }

  .basket-user{
    .table-item{
      .title{
        &:nth-child(1){
          width: 10%;
        }
        &:nth-child(3){
          width: 13%;
        }
        &:nth-child(5){
          width: 8%;
        }
      }
      .text{
        &:nth-child(2){
          width: 28%;
        }
        &:nth-child(4){
          width: 22%;
        }
        &:nth-child(6){
          width: 19%;
        }
      }
    }
  }

  .reservation-buttons{
    margin-top: 30px;
  }
}