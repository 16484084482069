.left-menu__item{
  font-size: 1.4rem;
  line-height: 16px;
  padding: 13px 25px 0 25px;
  //width: stretch;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  position: relative;

  .item__arrow{
    top: 0;
    right: 0;
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg{
      transition: $transition-base;
      path{
        fill: var(--superColorText);
        stroke: var(--superColorText);
      }
    }

    &.is-active{
      svg{
        transform: rotate(180deg);
      }
    }

    &:hover{
      svg{
        path{
          fill: var(--primaryColor);
          stroke: var(--primaryColor);
        }
      }
    }
  }

  .item__header{
    &.is-active{
      a{
        color: var(--primaryColor);
        border-bottom: 1px solid var(--primaryColor);
      }

      svg{
        path{
          fill: var(--primaryColor);
          stroke: var(--primaryColor);
        }
      }
    }
  }

  li{
    transition: $transition-base;

    &:not(:last-child){
      margin-bottom: 10px;
    }

    a{
      font-weight: 500;
      width: 100%;

      &.is-active{
        color: var(--primaryColor);
        display: inline;
      }
    }

  }

  a{
    color: var(--superColorText);
    text-decoration: none;
    justify-content: space-between;
    display: flex;
    align-items: center;

    &:hover{
      color: $primary-color;
    }

    &.is-active{
      color: var(--primaryColor);
      border-bottom: 1px solid var(--primaryColor);
    }

  }

}