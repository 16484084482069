.drop-menu {
  position: relative;
  @include respond-to('s') {
    background-color: var(--superColorBg);
  }
  .breadcrumbs {
    .share-element {
      svg {
        transition: $transition-base;
        @include respond-to('l') {
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }
  }
  &__content {
    @include respond-to('l') {
      visibility: hidden;
      opacity: 0;
      width: 100%;
      top: 0;
      right: 0;
      position: absolute;
    }
    &.show {
      @include respond-to('l') {
        visibility: visible;
        opacity: 1;
        z-index: 2;
      }
      @include respond-to('s') {
        background-color: var(--superColorBg);
        max-width: 100%;
      }
    }
    .drop-menu__lists {
      @include respond-to('l') {
        width: 35%;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: auto;
        margin-top: 0;
        border: none;
      }
      @include respond-to('m') {
        width: 50%;
      }
      @include respond-to('s') {
        width: 100vw;
        max-width: 540px;
        margin: 0 auto;
        height: 100vh;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: var(--superColorBg);
      }
      @include respond-to('xs') {
        max-width: 100%;
      }
      .drop-menu__header {
        @include respond-to('l') {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid var(--primaryColor);
          width: 100%;
          margin: 0;
          padding: 0 20px 20px;
        }
        .drop-menu-close {
          transition: $transition-base;
          @include respond-to('l') {
            text-transform: capitalize;
          }
          svg {
            @include respond-to('l') {
              margin-right: 10px;
            }
            path {
              fill: var(--superColorText);
              transition: $transition-base;
              &:hover {
                fill: var(--primaryColor2);
              }
            }
          }
          &:hover{
            svg path {
              fill: var(--primaryColor2);
            }
          }
        }
      }
    }
  }
  &-close, &-show {
    display: none;
    color: var(--superColorText);
    transition: $transition-base;
    &:hover {
      color: var(--primaryColor);
    }
    @include respond-to('l') {
      display: block;
      cursor: pointer;
      font-size: 1.3rem;
    }
    @include respond-to('s') {
      font-size: 1.1rem;
    }
  }
}
