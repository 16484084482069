.hamburger {
  &-menu {
    display: none;
    @include respond-to('m') {
      display: inline-block;
      padding: 10px 0;
    }
    @include respond-to('s') {
      padding:7px 0;
    }
    p {
      color: var(--primaryColor);
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.4rem;
      cursor: pointer;
      text-align: right;
      @include respond-to('s') {
        font-size: 1.1rem;
        margin-top: 6px;
      }
    }
    svg {
      width: 40px;
      height: 40px;
      fill: var(--superColorBg2);
      @include respond-to('s') {
        width: 30px;
        height: 22px;
      }
    }
  }
  &-icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .menu-icon {
      stroke: var(--primaryColor);
    }
  }
}