.header {
  padding-top: 10px;
  &-wrapper {
    @include respond-to('m') {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-navigation {
    @include respond-to('m') {
      position: fixed;
      background-color: var(--primaryColor2);
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      z-index: 2;
      transform: translateX(100%);
      transition: all ease-out 0.8s;
    }
    &.open {
      opacity: 1;
      visibility: visible;
      height: 100%;
      transform: translateX(0%);
    }
    .hamburger-menu {
      padding-left: 19px;
      @media (max-width: 320px) {
        padding-left: 0;
      }
    }
    &__wrapper {
      height: 60px;
      @include respond-to('m') {
        height: 100%;
        max-width: 720px;
        margin: 0 auto;
        padding: 0 15px;
      }
      @include respond-to('s') {
        max-width: 540px;
      }
      @include respond-to('xs') {
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
      }
      @media (max-width: 320px) {
        max-width: 320px;
      }
    }
    &__heading {
      display: none;
      &--mobile {
        @include respond-to('m') {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 14px;
          padding-bottom: 24px;
        }
        .search-section {
          cursor: pointer;
          margin-left: 31px;
          transition: $transition-base;
          @media (max-width: 320px) {
            margin-left: 5px;
          }
          svg path:first-child {
            fill: var(--superColorBg2);
            transition: $transition-base;
            &:hover {
              fill: $text-color;
            }
          }
        }
        .dynamic-fontSize {
          margin-right: 26px;
          .span-big {
            color: var(--superColorBg2);
            cursor: pointer;
            font-weight: 600;
            &:nth-child(2) {
              margin-left: 18px;
            }
            &:nth-child(3) {
              margin-left: 26px;
            }
            &:hover{
              color: $text-color;
            }
          }
        }
        .view-option {
          svg path {
            fill: var(--superColorBg2);
          }
        }
        .language-option {
          & > a, div {
            border: 1px solid var(--superColorBg2);
            &:hover {
              background-color: #fff;
              color: $primary-color;
            }
          }
          a {
            text-decoration: none;
            margin: 0;
            & + a {
              margin-left: 9px;
            }
          }
        }
      }
    }
    &__info {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: 320px) {
        justify-content: flex-start;
      }
    }
  }
}

.separate-div{
  width: 1px;
  height: 85%;
  background-color: var(--primaryColor);
  margin: 0 30px;
  @include respond-to('l') {
    margin: 0 20px;
  }
}

.separate-div, .header-rightSide, .info-text, .logo-M, .header-floor::after {
  @include respond-to('m') {
    display: none;
  }
}
.header-floor{
  padding-bottom: 10px;
  position: relative;
  &:after{
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    background-color: var(--primaryColor);
  }
}

.header-leftSide{
  .info-text{
    text-transform: uppercase;
    max-width: 150px;
    font-weight: 500;
    font-size: 11px;
    color: var(--superColorText);
  }
}

.logo-M.clicked{
  svg{
    path{
      fill: $white-color;
    }
  }
}
.logo-M {
  //display: flex;
  //display: -webkit-flex;
  background-image: url('../assets/images/logo/logoM.png');
  background-repeat: no-repeat;
  width: 81px;
  height: 39px;
  background-position: center;
  background-size: cover;
  &.clicked {
    background-image: url('../assets/images/logo/logoM-white.png');
  }
  @include respond-to('m') {
    display: none;
  }
}
.logo-FK {
  //display: flex;
  //display: -webkit-flex;
  background-image: url('../assets/images/logo/logo.png');
  background-repeat: no-repeat;
  width: 198px;
  height: 53px;
  background-position: center;
  background-size: cover;
  &.clicked {
    background-image: url('../assets/images/logo/logo-white.png');
  }
  @include respond-to('s') {
    width: 147px;
    height: 39px;
  }
  svg {
    @include respond-to('s') {
      width: 147px;
      height: 39px;
    }
  }
}
.logo-FK.clicked{
  svg{
    path{
      fill: $white-color;
    }
  }
}

.header-rightSide{
  .language-option{
    > div, a{
      text-decoration: none;

      &.is-active{
        background-color: var(--primaryColor);
        border-color: var(--primaryColor);

        span{
          color: var(--superColorBg);
          transition: $transition-base;
          @include respond-to('m') {
            color: var(--superColorBg2);
          }
        }

        &:hover span{
          color: $primary-color;
        }

      }
    }
  }

  .dynamic-fontSize {
    @include respond-to('l') {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    & > span {
      margin-right: 23px;
      cursor: pointer;
      transition: $transition-base;

      &.is-active{
        color: $primary-color;
      }

      &:hover{
        color: $primary-color;
      }
    }
  }

  .search-section{
    margin-left: 50px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    transition: $transition-base;
    @include respond-to('l') {
      margin-left: 13px;
    }
    svg path:first-child{
      fill: var(--superColorText);
      transition: $transition-base;
    }
    svg path:last-child{
      fill: var(--superColorBg);
    }

    svg{
      margin-right: 18px;
    }

    &:hover{
      svg path:first-child{
        fill: $primary-color;
      }
      color: $primary-color;
    }


  }
}

.social-media, .language-option{
  >div, a{
    border: 1px solid var(--superColorText);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    @include respond-to('xl') {
      margin-right: 5px;
    }
    cursor: pointer;

    &:last-child{
      margin-right: 0;
    }

    svg path{
      fill: var(--superColorText);
    }

    span {
      color: var(--superColorText);
      @include respond-to('m') {
        color: var(--superColorBg2);
      }
    }
  }

  .view-option{
    &.clicked{
      background-color: yellow;
      border-color: yellow;

      svg path{
        fill: #000;
      }
    }
  }
}

.social-media, .language-option{
  >div, a{
    transition: $transition-base;
    svg path, span {
      transition: $transition-base;
    }
    &:hover{
      border-color: $primary-color;

      svg path{
        fill: $primary-color;
      }

      span{
        color: $primary-color;
      }

      &.is-active{
        background-color: $white-color;
      }
    }
  }
}