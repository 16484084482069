.footer-line {
  background-color: var(--primaryColorLight2);
  //border: 1px solid var(--primaryColor);
  border-left: none;
  border-right: none;

  .footer-list {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    @include respond-to('s') {
      flex-direction: column;
      text-align: center;
      padding: 35px 0;
    }
    .list-item {
      & + .list-item {
        @include respond-to('s') {
          padding-top: 20px;
        }
      }
    }
    a {
      text-decoration: none;
      color: $text-color;
      font-weight: 600;
      text-transform: uppercase;
      transition: $transition-base;
      &:hover{
        color: $primary-color;
      }
    }
  }
}
.to-flex-jc {
  @include respond-to('m') {
    //flex-direction: column;
    flex-wrap: wrap;
  }
}
.footer-block{
  padding: 20px 0;
  .logo-footer-FK {
    &.clicked{
      svg{
        path{
          fill: $white-color;
        }
      }
    }
    @include respond-to('m') {
      text-align: center;
      margin-bottom: 40px;
    }
    @include respond-to('s') {
      margin-bottom: 0;
    }
      svg {
        @include respond-to('m') {
          width: 114px;
          height: 98px;
        }
      }
  }
  .block-column{
    @include respond-to('s') {
      width: 50%;
      margin-bottom: 20px;
    }
    &:first-child {
      @include respond-to('m') {
        width: 100%;
      }
    }
    &:nth-child(3){
      margin-top: 27px;
    }

    &--title{
      margin-bottom: 15px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--superColorText);
    }

    a{
      text-decoration: none;
      margin: 5px 0;
      color: var(--superColorText);
      font-weight: 500;
      transition: $transition-base;

      &:hover{
        color: $primary-color;
      }
    }

    .social-media{
      @include respond-to('s') {
        justify-content: space-between;
      }
      a{
        margin-right: 18px;
        @include respond-to('s') {
          margin-right: 0;
        }
      }
    }

    .column-address{
      margin-bottom: 10px;

      span{
        margin-bottom: 2px;
      }
    }
  }
}

.signature-Ultimate{
  border-top: 1px solid var(--primaryColor);
  padding: 5px 0;
  @include respond-to('s') {
    padding: 15px 0;
  }
  .powered-by{
    margin-left: auto;
    display: flex;
    align-items: center;
    @include respond-to('s') {
      margin-left: 0;
      margin-top: 8px;
    }
    a {
      margin-left: 15px;
    }
  }
  .to-flex-ai {
    @include respond-to('s') {
      flex-direction: column;
    }
  }
  .adminMail{
    display: block;
    text-decoration: none;
    color: $text-color;
    margin-top: 7px;

    &:hover{
      color: $primary-color;
    }
  }
}

.logo-Ultimate.clicked{
  svg{
    path{
      fill: $white-color;
    }
  }
}