// Library
@import "../../node_modules/swiper/css/swiper.min.css";
@import "../../node_modules/lightpick/css/lightpick.css";
@import "../../node_modules/flatpickr/dist/flatpickr.min.css";
@import "../../node_modules/lightbox2/dist/css/lightbox.min.css";

// ============================================
// Abstracts
// ============================================

@import
  "Abstracts/settings",
  "Abstracts/mixins";


// ============================================
// Vendor
// ============================================

//@import "Swiper/dist/css/swiper";


// ============================================
// Base
// ============================================

@import "Base/reset";
@import "Base/font";
@import "Base/flex";
@import "Base/span";
@import "Base/buttons";
@import "Base/animations";


// ============================================
// Layout
// ============================================

//@import "Layout/smth";

//header
@import "components/header/header";
@import "components/header/nav";
@import "components/header/search";

//footer
@import "components/footer/footer";
// container
@import "components/container/main-container";
//lightpick
@import "components/lightpick/lightpick";

//loader
@import "components/loader/load-container";
@import "components/loader/icon";
@import "components/loader/itemsLoader";

//list
@import "components/list/list";

// ============================================
// Componentss
// ============================================
@import "components/byPage/home/section";
@import "components/byPage/home/newsletter";
@import "components/byPage/home/news";

@import "components/checkbox/checkbox";

@import "components/byPage/searchPage/search-page";

@import "components/scrollbar/scrollbar";

@import "components/byPage/news/list";

@import "components/breadcrumbs/breadcrumbs";

@import "components/byPage/about/list";
@import "components/byPage/about/show";
@import "components/byPage/about/building/building";
@import "components/byPage/about/discography/discography";

@import "components/lists-block/lists-block";


@import "components/byPage/repertoire/repertoire";
@import "components/byPage/repertoire/repertoireShow";

@import "components/byPage/gallery/list";
@import "components/byPage/gallery/show";

@import "components/byPage/cycles/cycles";

@import "components/listFile/listFile";
@import "components/byPage/contact/contact";

@import "components/byPage/directorate/directorate";

@import "components/byPage/reservation/reservation";
@import "components/byPage/reservation/form";
@import "components/byPage/reservation/basket";
@import "components/byPage/reservation/pay";

@import "components/dropdown/dropdown";
@import "components/dropdown/drop-menu";

@import "components/menu/hamburger-menu";
@import "components/menu/left-menu";
@import "components/upToArrow/upToArrow";

@import "components/byPage/login/login";


// ============================================
// Modules
// ============================================

//@import "Module/smth";

// ============================================
// State
// ============================================

//@import "State/smth";



// ============================================
// Shame
// ============================================

// @import "Shame/smth";

// main


@import "main";
@import "themes/themes";
