.search-input{
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid var(--superColorBg2);
  padding: 10px 0;
  font-family: Raleway, serif;
  margin-right: 20px;
  height: 39px;
  font-weight: 500;
  font-size: 2.0rem;
  color: var(--superColorBg2);
  @include respond-to('m') {
    margin-right: 0;
  }
  &:focus, &:active{
    outline: none;
  }

  &::placeholder{
    color: var(--superColorBg2);
  }

}

.search-blur{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  //background-color: rgba(210, 171, 103, 0.9);
  background-color: var(--primaryColor2);
  opacity: .9;
  @include respond-to('m') {
    flex-direction: column;
    opacity: 1;
  }
  .closeIcon{
    position: absolute;
    right: 31%;
    top: 35%;
    cursor: pointer;
    @include respond-to('m') {
      right: 20px;
      top: 20px;
    }

    svg{
      path{
        fill: var(--superColorBg2);
      }
      @include respond-to('m') {
        width: 22px;
        height: 22px;
      }
    }
  }

  .error-label{
    height: 12px;
  }
  .search-content {
    width: 30%;
    max-width: 30%;
    @include respond-to('m') {
      width: 100%;
      max-width: 96%;
    }
  }
}