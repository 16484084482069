.reservationPage--pay{
  margin: 50px 0;

  .section-title-upper{
    margin-bottom: 40px;
  }

  .pay-item{
    border: 1px solid var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--superColorText);
    width: 198px;
    height: 198px;
    font-weight: 600;
    font-feature-settings: 'pnum' on, 'lnum' on;
    cursor: pointer;
    font-size: 1.6rem;

    &:not(:last-child){
      margin-right: 35px;
    }

    &:hover{
      background-color: $primary-light-color;
    }

    &.is-active{
      background-color: var(--primaryColor);
      color: var(--superColorBg);
    }
  }

  .reservation-buttons{
    margin-top: 40px;
  }
}

.reservationPage--msg{
  min-height: 400px;

  .reservationFinish-svg{
    font-weight: 600;
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--superColorText);
    margin-bottom: 80px;

    svg{
      margin-right: 23px;

      path{
        fill: var(--primaryColor);
      }
      circle{
        stroke: var(--primaryColor);
      }
    }

  }
}