.list-downloadFile{

  .section-title{
    margin: 30px 0;
  }

  .file-item:not(:last-child){
    margin-bottom: 10px;
  }

  svg{
    margin-right: 10px;

    path{
      fill: var(--superColorText);
    }
  }

  a{
    color: var(--superColorText);
    text-decoration: none;

    &:hover{
      color: $primary-color;
    }
  }

}

.list-with-title{
  margin-bottom: 20px;
  color: var(--superColorText);

  .section-title{
    margin-top: 20px;
    margin-bottom:10px;
  }

  ul{
    line-height: 156.5%;
  }
}