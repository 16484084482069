.directorate {
  .section-title-upper {
    @include respond-to('s') {
      text-align: left;
      margin-bottom: 25px;
    }
  }
  .section-staff--info {
    .xs-span {
      @include respond-to('xs') {
        display: inline-block;
      }
    }
    img {
      @include respond-to('s') {
        width: 165px;
        height: 233px;
        padding: 0;
        margin-bottom: 20px;
        margin-left: 20px;
      }
    }
    .staff-block--name {
      @include respond-to('xs') {
        margin-bottom: 0;
      }
    }
    .downloadFile {
      width: 100%;
      text-align: right;
      padding: 20px 0;
    }
  }
}