.drop-down {
  position: relative;
  .selected {
    .select-item {
      border-bottom: 1px solid var(--superColorText);
      //width: 172px;
      text-decoration: none;
      color: var(--superColorText);
      padding: 10px 0;
      font-size: 1.3rem;
      line-height: 137.5%;
      cursor: pointer;
    }

    .arrow{
      transition: 0.3s;

      svg path{
        fill: var(--superColorText);
      }
    }
  }

  .options {
    ul {
      display: none;
      position: absolute;
      left: 0;
      top: 40px;
      width: 100%;
      min-width: 150px;
      font-size: 1.4rem;
      z-index: 1;
      animation: fadeIn .3s;
      border: 1px solid var(--primaryColor);
      border-top: none;
    }

    li {
      padding: 7px;
      display: block;
      text-decoration: none;
      color: var(--superColorText);
      cursor: pointer;

      label {
        cursor: pointer;
      }

      &:hover {
        color: $primary-color;
      }

      &.is-active{
        color: $primary-color;
        font-weight: 600;
      }
    }
  }
}