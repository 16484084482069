// flex
.to-flex {
  display: flex;
}

// flex with justify
.to-flex-jc {
  display: flex;
  justify-content: space-between;
}

// to center
.to-flex-ai {
  display: flex;
  align-items: center;
}

// to-flex-js-ai
.to-flex-jc-ai {
  @extend .to-flex-jc;
  align-items: center;
}

// flex column
.to-flex-cl {
  @extend .to-flex;
  flex-direction: column;
}

// flex column end
.to-flex-cl-end {
  @extend .to-flex-cl;
  align-items: flex-end;
}

// flow row reverse
.to-flex-rowR {
  flex-direction: row-reverse;
}

// justify/align - center
.to-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// center endline
.to-flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// justify/align - center
.to-flex-cl-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.to-flex-cl-jc {
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: column;
}

.to-flex-end {
  @extend .to-flex;
  justify-content: flex-end;
}

// columna end
.to-flex-column-end {
  @extend .to-flex-end;
  flex-direction: column;
}


// wrap
.to-flex-wrap {
  @extend .to-flex;
  flex-wrap: wrap;
}