.repertoire-show {
  .right-container {
    @include respond-to("l") {
      flex: 1;
      width: 100%;
      margin: 20px 0 40px 20px;
    }
    @include respond-to("m") {
      display: flex;
      flex-direction: column;
    }
    @include respond-to("s") {
      margin: 20px 0 40px;
    }
    .noeditor {
      width: 407px;
      height: 260px;
      object-fit: cover;
      @include respond-to("m") {
        padding: 20px 0;
        float: none;
        width: 100%;
        height: auto;
      }
    }

    .section-title-low {
      margin-top: 50px;
      margin-bottom: 15px;
      font-style: italic;

      &:first-child {
        margin-top: 25px;
      }

      @include respond-to("s") {
        text-align: left;
        margin-top: 20px;
      }
    }

    .section-line {
      margin: 24px 0;

      @include respond-to("s") {
        margin: 10px 0;
      }
    }
  }

  .calendar-card {
    .border-line {
      padding: 23px 0;
      border-bottom: 1px solid var(--primaryColor);
      display: flex;
      flex-direction: column;
      align-items: center;
      font-feature-settings: "pnum" on, "lnum" on;
      color: var(--superColorText);
      @include respond-to("l") {
        padding: 15px 0;
      }
      @include respond-to("m") {
        padding: 10px 0;
      }

      &.space {
        line-height: 137%;
        font-weight: 500;
      }
    }

    .card-day {
      font-size: 3.8rem;
      font-weight: 300;
      line-height: 137%;
      @include respond-to("m") {
        font-size: 2.8rem;
      }
    }

    .card-week {
      font-size: 1.8rem;
      line-height: 137%;
      @include respond-to("m") {
        font-size: 1.4rem;
      }
    }

    .card-hours {
      font-weight: 500;
    }

    .card-price {
      padding-top: 23px;
      padding-bottom: 10px;
      font-size: 1.2rem;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-feature-settings: "pnum" on, "lnum" on;
      color: var(--superColorText);
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1.5;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      @include respond-to("m") {
        font-size: 1.1rem;
      }
    }

    .primary-btn {
      margin-bottom: 15px;
    }
    &--desktop {
      @include respond-to("s") {
        display: none;
      }
    }
    //Calendar card mobile version
    &--mobile {
      display: none;
      @include respond-to("s") {
        display: block;
        background-color: var(--primaryColorLight);
        border: 1px solid var(--primaryColorLight2);
        padding: 10px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 20px;
        color: var(--superColorText);
      }
      .calendar-card__info {
        span {
          @include respond-to("s") {
            display: block;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
      .primary-btn {
        @include respond-to("s") {
          margin-bottom: 0;
        }
      }
    }
  }
}
