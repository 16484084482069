.discography {
  @include respond-to('l') {
    width: 100%;
    position: relative;
  }
  .section-staff {
    @include respond-to('l') {
      width: 100%;
      margin: 20px 0;
    }
  }
  .section-title-upper {
    @include respond-to('s') {
      text-align: left;
    }
  }
  &-list{
    display: grid;
    grid-template-columns: repeat(4,calc(100% / 4 - 15px));
    grid-gap: 20px;
    margin-bottom: 20px;

    @include respond-to('m') {
      grid-template-columns: repeat(3,calc(100% / 3 - 15px));
    }
    @include respond-to('s') {
      grid-template-columns: repeat(2,calc(100% / 2 - 5px));
      grid-gap: 9px;
    }
  }
  .text-around-picture--right {
    //img {
    //  padding: 0;
    //}
  }

  .discography-item {
    cursor: pointer;
    &:hover {
      .discography-title {
        .xs-span {
          color: $primary-color;
        }
      }
    }
    @include respond-to('s') {
      margin-bottom: 20px;
    }

    .discography {

      &-picture {
        min-width: 100px;
        height: 100px;
        margin: 0 auto 10px;
        text-align: center;
      }
      &-title{
        display: flex;
        flex-direction: column;
        text-align: center;
        font-weight: 600;
        color: var(--superColorText);
        .xs-span {
          transition: $transition-base;
          @include respond-to('s') {
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 19px;
          }
        }
        span:first-child{
          margin-bottom: 5px;
        }
      }

    }
  }

  &-show {
    .section-title {
      margin-bottom: 10px;
    }

    .xs-span {
      margin-bottom: 7px;
      color: var(--superColorText);
    }
    .section-authors {
      display: flex;
      justify-content: space-between;
    }
    .section-media {
      margin-bottom: 100px;
      display: flex;
      justify-content: space-between;
      @include respond-to('m') {
        flex-direction: column;
      }

      .section-title {
        margin-bottom: 20px;
      }
      .media-video {
        max-width: 409px;
        width: 100%;
        @include respond-to('m') {
          min-width: 342.33px;
          max-width: 100%;
        }
        iframe {
          width: 100%;
          height: 249px;
          @include respond-to('s') {
            height: 208.11px;
          }
        }
      }
      & > div {
        margin-top: 46px;
      }
      .audio-item {
        margin-bottom: 10px;
        font-weight: 500;

        audio {
          height: 30px;
          width: 100%;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}


