.news-list {
  position: relative;
  .block-item {
    .section-title {
      transition: $transition-base;
    }
    &:hover {
      .section-title {
        color: $primary-color;
      }
    }
  }
}

.news-container {
  &.show {
    .section-title-upper {
      margin: 10px 0 30px 0;
    }

    p {
      line-height: 156.5%;
    }

    .new-show-content__main-img {
      width: auto;
      height: 300px !important;
      float: right;
      object-fit: cover;
      margin: 20px 0 20px 20px;
    }

    margin-bottom: 30px;
    min-height: auto;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.news-other-event {
  margin-top: 40px;
}
.news-show--header {
  font-feature-settings: "pnum" on, "lnum" on;
  font-weight: 600;
  color: var(--superColorText);
  margin-bottom: 20px;
}
.new-show-content {
  color: var(--superColorText);
}

//Styles for archive news subpage - rwd
.archive {
  &-news {
    .left-container {
      @include respond-to("s") {
        display: none;
      }
    }
    .news-container {
      .section-title-upper {
        @include respond-to("s") {
          text-align: left;
          padding: 25px 0 0;
        }
      }
    }
    // Styles for archive news item subpage - rwd
    &-item {
      .news-container.show {
        @include respond-to("m") {
          width: 100%;
          margin-left: 0;
        }
        img {
          @include respond-to("l") {
            width: 100% !important;
            float: none;
            margin: 0 0 20px;
            height: auto;
          }
        }
        .section-title-upper {
          @include respond-to("s") {
            margin: 21px 0 36px 0;
            text-align: left;
          }
        }
      }
      .section-news {
        .left-container {
          @include respond-to("m") {
            display: none;
          }
        }
      }
      .right-container {
        @include respond-to("m") {
          width: 100%;
          margin: 20px 0;
        }
        @include respond-to("s") {
          margin-top: 15px;
        }
      }
    }
  }
  .image {
    @include respond-to("m") {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 28px 0;
      height: auto;
    }
    img {
      @include respond-to("m") {
        width: 100%;
        height: auto;
        padding-right: 0;
        padding-bottom: 12px;
      }
    }
  }
  .block-content {
    @include respond-to("m") {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column-reverse;
    }
  }
  .block-data {
    @include respond-to("m") {
      margin-bottom: 10px;
      color: var(--grayTextData);
      font-weight: 600;
    }
  }
}
