html,
body {
  overflow-x: hidden;
}
html {
  box-sizing: border-box;
  &.overflow-y {
    overflow-y: hidden;
  }
}
*,
::after,
::before {
  box-sizing: inherit;
}
body {
  font-size: 1.4rem;
  @include respond-to("s") {
    font-size: 1.3rem;
  }
}
.d-none {
  display: none;
}

.full-width {
  width: 100%;
}

.blurScreen {
  filter: blur(2px);
}

.primary-text {
  color: $primary-color;
}

.error-label {
  color: $error-color;
  font-weight: 500;
  margin-top: 5px;
}

.ml-auto {
  margin-left: auto;
}

.width-30 {
  width: 30%;
}

.note-float-left {
  float: left;
  padding: 10px 10px 10px 0;
  @include respond-to("s") {
    padding: 10px;
  }
}

.note-float-right {
  float: right;
  padding: 10px 0 10px 10px;
  max-width: 290px !important;
  @include respond-to("s") {
    padding: 10px 0;
  }
}

p {
  line-height: 156.5%;
  color: var(--superColorText);
  @include respond-to("s") {
    line-height: 166.5%;
  }
}

.rotate_180 {
  transform: rotate(180deg);
}

.staticPages {
  //Overwrite cms editor styles

  .note-float-right {
    width: auto;
    height: auto;
  }

  .swiper-container {
    width: 49%;
    float: right;
    margin-left: 10px;
    @include respond-to("s") {
      width: 100%;
      margin-left: 0;
    }

    .swiper-button-next,
    .swiper-button-prev {
      bottom: -2px;
      top: unset;

      &:after {
        font-size: 13px;
      }
    }

    .swiper-wrapper {
      height: 88%;
    }

    .swiper-button-prev {
      right: 58%;
      left: unset;
    }

    .swiper-button-next {
      left: 58%;
    }

    .swiper-slide {
      width: 100% !important;
    }

    .swiper-lightbox {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  .section-title-upper {
    @include respond-to("s") {
      text-align: left;
    }
  }
  @include respond-to("s") {
    display: flex;
    flex-direction: column;
  }
  img {
    @include respond-to("s") {
      padding: 0 0 20px;
      width: 100% !important;
    }
  }
}

b,
strong {
  font-weight: 600;
}
em,
strong,
font,
u {
  //color: var(--superColorText) !important;
}
.email-toggle-wrapper {
  display: flex;
  align-items: center;
  margin: 0 !important;
  .defaultShow {
    margin: 0 !important;
  }
  .btn-show-email {
    background: transparent;
    border: 0;
    padding: 0;
    margin-left: 20px;
    text-decoration: underline;
    cursor: pointer;
  }
}

img {
  @media (max-width: 767px) {
    max-width: 100% !important;
    height: auto !important;
  }
}
