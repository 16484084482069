.xss-span {
  font-size: 1.2rem;
  color: var(--superColorText);
}

.xs13-span {
  font-size: 1.3rem;
  color: var(--superColorText);

  //@media (max-width: $xmedium) {
  //  font-size: 11px;
  //}
}

.xs-span {
  font-size: 1.4rem;
  //color: var(--superColorText);
  @include respond-to('s') {
    font-size: 1.3rem;
    font-weight: 500;
  }

  &.bold{
    font-weight: 600;
  }

  //@media (max-width: $xlarge) {
  //  font-size: 11px;
  //}
  //@media (max-width: $medium) {
  //  color: #646f9b;
  //  font-size: 11px;
  //}
}

.section-title{
  color: var(--superColorText);
  font-weight: 600;
  font-size: 1.5rem;
  width: 100%;
  @include respond-to('s') {
    font-size: 1.4rem;
  }

  &-upper{
    @extend .section-title;
    text-transform: uppercase;
    @include respond-to('s') {
      text-align: center;
    }
  }

  &.mr-20{
    margin: 20px 0;
  }
}

.section-mini-title{
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: $text-color;
}

.span-big{
  font-size: 1.8rem;
  color: var(--superColorText);

  //@media (max-width: $xmedium) {
  //  font-size: 15px;
  //}
}

// primary text color
.primary-text-color {
  color: $primary-color;
}

// text i 3 kropki
.cut-text {
  @include respond-to('m') {
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }

  //@media (max-width: $medium) {
  //  display: inline-block;
  //}
}

// text z zawinięciem i 3 kropki //  -webkit-box-orient: vertical; -> inline style
.cut-text-wrap {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.wrap-2{
    -webkit-line-clamp: 2;
  }
}