[container-type="cycles"]{
  .section-title-upper {
    @include respond-to('s') {
      text-align: left;
    }
  }
  .repertoire-section{
    .repHead{

      @include respond-to('s') {
        grid-template-columns: 100%;
      }
      .drop-down {
        @include respond-to('m') {
          width: 100%;
          padding: 10px 0;
        }

        .select-item {
          @include respond-to('s') {
            width: 100%;
          }
        }

        &:first-child {
          margin-left: 20px;
          @include respond-to('l') {
            margin-left: 0;
          }
        }

        .options ul {
          background-color: var(--superGrayBlack);
        }
      }
      .select-item{
        width: 100%;
      }


    }

    .block-item:hover{
      cursor: unset;
    }
  }
  .drop-down .selected .select-item {
    @include respond-to('xl') {
      font-size: 1.1rem;
    }
    @include respond-to('l') {
      font-size: 1.3rem;
    }
  }
}