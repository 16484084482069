.section-gallery--swiper{

  .swiper-button-next, .swiper-button-prev{
    border: 1px solid var(--primaryColor2);
    background-color: var(--superColorBg2);
    padding: 10px;
    color: var(--primaryColor2);

    &:focus{
      outline: none;
    }

    &:after{
      font-size: 16px;
    }

    &:hover{
      background-color: $primary-color;
      color: $white-color;
    }
  }

  .swiper-button-prev{
    left:0;
  }

  .swiper-button-next{
    right: 0;
  }

  .gallery-top{
      height: 480px;

    .swiper-slide{
      width: 100% !important;
      margin-right: 0;

      .swiper-lightbox{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

  }

  .gallery-thumbs{

    .swiper-wrapper{
      transform: translate3d(0,0,0) !important;
      flex-wrap: wrap;

      .swiper-slide{
        height: 120px;
        margin: 6px 0;

        &:nth-child(3n){
          margin-right: 9px !important;
        }

        &:nth-child(4n){
          margin-right: 0 !important;
        }

      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 80%;
  width: 100%;
}
.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}