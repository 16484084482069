.cbx {
  position: relative;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 30px;
    padding-left: 35px;
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: var(--superColorText);
    line-height: 1.2;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    a{
      color: var(--superColorText);

      &:hover{
        color: $primary-color;
      }
    }
  }

  input[type="checkbox"] + label:last-child {
    margin-bottom: 0;
  }

  input[type="checkbox"] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid var(--primaryColor);
    position: absolute;
    left: 0;
    top: 5px;
    opacity: .6;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
  }

  input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: 1px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border: 2px solid var(--primaryColor);
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.error{
    .error-label{
      display: block;
      position: absolute;
      bottom: 9px;
      font-size: 11px;
      left: 34px;
    }

    input[type=checkbox]+label:before{
      border-color: $error-color;
      opacity: 1;
    }

    input[type="checkbox"]:checked + label:before {
      border-top-color: transparent;
      border-left-color: transparent;
    }


  }
}