//arrow go to left
@keyframes animationArrow {
  0% {
    transform: translateX(0px)
  }
  50% {
    transform: translateX(-5px)
  }
  100% {
    transform: translateX(0px)
  }
}

//arrow go to down
@keyframes animationArrowDown {
  0% {
    transform: translateY(0px)
  }
  50% {
    transform: translateY(-5px)
  }
  100% {
    transform: translateY(0px)
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateX(-3px);
  }

  to   {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}