// --------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: 1258px; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 15px; /* 3 */
  padding-right: 15px; /* 3 */
  @include respond-to("xl") {
    max-width: 1140px;
  }
  @include respond-to("l") {
    max-width: 968px;
  }
  @include respond-to("m") {
    max-width: 720px;
  }
  @include respond-to("s") {
    max-width: 540px;
  }
  @include respond-to("xs") {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-container {
  position: relative;
  &--change-size {
    .container {
      &.change-size {
        @include respond-to("s") {
          max-width: 100%;
          padding: 0;
        }
      }
    }
  }
}

.left-container {
  width: 25%;

  .left-container--menu {
    padding: 20px 0;

    .section-title-upper {
      padding: 13px 25px;
      //width: stretch;
    }
  }
}

.right-container {
  width: 75%;
  margin: 20px;
  min-height: 650px;
  line-height: 156.5%;

  &.noMinHeight {
    min-height: auto;
  }
  &--full-width {
    @include respond-to("l") {
      width: 100%;
      margin: 20px 0;
    }
  }

  a {
    color: var(--superColorText) !important;
    text-decoration: none;
    transition: $transition-base;

    &:hover {
      color: $primary-color !important;
    }
  }
}

.section-logos {
  display: grid;
  grid-template-columns: repeat(4, calc(100% / 4 - 15px));
  grid-gap: 20px;
  justify-items: center;
  margin-top: 20px;

  img {
    object-fit: scale-down;
    width: 100%;
  }
}

.double-block {
  margin-bottom: 40px;

  .section-title-upper {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .news-item {
    width: 48%;
  }

  .news-item--picture {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  .downloadFile {
    padding: 10px 0;
    justify-content: flex-end;
  }
}

.dynamic-content {
  position: relative;
}
