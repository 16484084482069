.search-section-content{
  margin-bottom: 50px;

  .section-title-upper{
    margin: 40px 0;
  }

  .block-item, .block-content{
    margin-bottom: 20px;
  }

  a{
    display: block;
    text-decoration: none;
  }

  .primary-btn{
    width: 210px;
  }
}

.search-tips{
  margin-bottom: 20px;

  >div{
    margin: 10px 0;
    line-height: 189.5%;
    position: relative;
    padding-left: 14px;
    color: var(--superColorText);

    &:before{
      content: '';
      width: 6px;
      height: 6px;
      background-color: var(--primaryColor);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 10px;
    }
  }
}