.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 13px 33px;
  cursor: pointer;
  border: 1px solid var(--primaryColor);
  text-decoration: none;
  transition: $transition-base;
  @include respond-to('s') {
    font-size: 1.1rem;
  }
  @media (max-width: 320px) {
    font-size: 1rem;
  }

  &:focus, &:active{
    outline: none;
  }

  &.disabled{
    opacity: 0.5;
    pointer-events: none;
  }

  &.error-ticket{
    @extend .disabled;
  }
}

.primary-btn{
  @extend .btn;
  background-color: var(--primaryColor);
  color: var(--superColorBg);
  transition: $transition-base;

  &:hover{
    background-color: darken($primary-color, 20%);
    border-color: darken($primary-color, 20%);
  }
}

.primary-btn-light{
  @extend .btn;
  background-color: var(--superColorBg2);
  color: var(--primaryColor2);
  transition: $transition-base;

  &:hover{
    background-color: darken($primary-color, 20%);
    border-color: darken($primary-color, 20%);
    color: var(--primaryColor2);
  }
}

.pagination-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--superGray);
  color: var(--superGray);
  font-weight: 600;
  cursor: pointer;
  width: 58px;
  font-size: 1.2rem;
  height: 43px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  transition: $transition-base;

  &.is-active{
    background-color: var(--primaryColor);
    color: var(--superColorBg);
    border: 1px solid var(--primaryColor);

    &:hover{;
      background-color: darken($primary-color, 20%);
      border-color: darken($primary-color, 20%);
    }
  }

  &:hover{
    background-color: lighten($gray-light, 20%);
  }
}

.light-btn{
  width: 33px;
  height: 33px;
  border: 1px solid var(--primaryColor);
  background-color: var(--superColorBg2);
  margin-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: $transition-base;

  path {
    fill: var(--primaryColor2);
    stroke-width: 0;
  }

  &.rotate{
    transform: rotate(180deg);
  }

  &:hover{
    background-color: $primary-color;

    path{
      fill: $white-color;
    }
  }
}

.downloadFile{
  padding: 20px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  color: var(--superColorText);
  white-space: nowrap;
  transition: $transition-base;

  svg{
    margin-right: 5px;
    transition: $transition-base;

    path{
      fill: var(--superColorText);
    }
  }
  &:hover{
    color: $primary-color;
    svg {
      animation: .8s animationArrowDown infinite;
      path {
        fill: $primary-color;
      }
    }
  }
}