$background-gray: #222;
$my-green: #ad0;
$light-gray: #bbb;
$dark-gray: #555;

.calendar {
  padding: 20px 0;
  width: 100%;
  @include respond-to('s') {
    padding: 25px 0 10px;
  }

  .calendar__header {
    padding: 10px 0;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &--month, &--year {
      letter-spacing: 0.06em;
      font-weight: 600;
      font-size: 1.6rem;
      color: var(--superColorText);
      font-feature-settings: 'pnum' on, 'lnum' on;
      @include respond-to('s') {
        font-size: 1.3rem;
      }
    }

    &--month{
      margin-right: 10px;
    }

    &--arrow{
      cursor: pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      &:hover {
        svg path {
          fill: #D2AB67;
          stroke: #D2AB67;
        }
      }

      svg path{
        fill: var(--superColorText);
        stroke: var(--superColorText);
      }

      &.arrow-left{
        left: 18px;
        transform: rotate(90deg);
        @include respond-to('s') {
          left: 0;
        }
      }

      &.arrow-right{
        right: 18px;
        transform: rotate(270deg);
        @include respond-to('s') {
          right: 0;
        }
      }
    }

  }

  .calendar__week, .calendar__days {
    display: grid;
    grid-template-columns: repeat(7,1fr);
    font-size: 1.3rem;
    color: var(--superColorText);
    padding: 14px 15px 0;
    grid-gap: 5px;
    @include respond-to('s') {
      padding: 14px 0 0;
      grid-gap: 2px;
    }
  }

  .calendar__week{
    font-weight: 500;
    text-align: center;
  }

  .calendar__days {
    @include respond-to('s') {
      position: relative;
      width: 100%;
    }
  }
  .date {
    color: var(--superColorText);
    border-radius: 50%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 600;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    justify-self: center;
    grid-gap: 4px;
    @include respond-to('s') {
      background-color: var(--primaryColorLight);
      border-radius: 0;
      width: 100%;
      height: 31px;
      grid-gap: 1px;
    }

    &.out-of-scope {
      color: var(--superGrayLine);
    }

    &.is-event {
      background-color: var(--primaryColor);
      color: var(--superColorBg);
      cursor: pointer;
      position: relative;
      animation: fadeInText .5s;
      transition: $transition-base;
      border: 2px solid transparent;
      &:hover {
        border: 2px solid var(--primaryColor);
        color: var(--primaryColor);
        background-color: transparent;
      }
      @include respond-to('s') {
        position: unset;
      }
    }

    .to-flex-center{
      width: 100%;
      height: 100%;
    }
  }
}