.header-navigation {
  .menu {
    height: 100%;
    @include respond-to('m') {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
    li {
      @include respond-to('m') {
        border-bottom: 1px solid var(--superGrayYellow);
        //padding: 22px 0 10px;
      }
    }
    a {
      text-decoration: none;
      color: var(--superColorText);
      position: relative;
      padding-bottom: 2px;
      font-weight: 600;
      @include respond-to('m') {
        color: var(--superColorBg2);
        padding-bottom: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    .menu-item {
      font-size: 1.3rem;
      text-transform: uppercase;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      transition: $transition-base;
      & + .menu-item {
        margin-left: 40px;
        @include respond-to('xl') {
          margin-left: 30px;
        }
        @include respond-to('l') {
          margin-left: 18px;
        }
        @include respond-to('m') {
          margin-left: 0
        }
      }
      @include respond-to('m') {
        font-size: 1.4rem;
        height: 50px;
        width: 100%;
      }
      @media (min-width: 992px) {
        .main-link {
          transition: $transition-base;
        }
        &.is-actived {
          .main-link {
            color: $primary-color;
            text-decoration: none;
            @include respond-to('m') {
              color: $text-color;
            }
          }
          .item-submenu {
            display: block;
            animation: .4s fadeIn;
            padding-top: 5px;
            @include respond-to('m') {
              display: none;
            }
          }
          .dropdown-menu:not(.is-show) {
            display: block;
            animation: .4s fadeIn;
            min-width: 250px;
            padding-top: 10px;
            @include respond-to('m') {
              display: none;
            }
          }
          .dropdown-submenu{
            position: relative;
            display: flex;
            align-items: center;
            .back{
              display: none;
            }
            &.is-actived {
              .dropdown-menu {
                top: -10px;
                display: block;
                animation: .4s fadeIn;
                margin-top: 8px;
                @include respond-to('m') {
                  display: none;
                }
              }
            }
            &:hover {
              .dropdown-menu {
                top: -10px;
                display: block;
                animation: .4s fadeIn;
                margin-top: 8px;
                @include respond-to('m') {
                  display: none;
                }
              }
            }
          }
        }
        &:hover {
          .main-link {
            color: $primary-color;
            @include respond-to('m') {
              color: $text-color;
            }
          }
          .item-submenu {
            display: block;
            animation: .4s fadeIn;
            padding-top: 5px;
            @include respond-to('m') {
              display: none;
            }
          }
          .dropdown-menu:not(.is-show) {
            display: block;
            animation: .4s fadeIn;
            min-width: 250px;
            padding-top: 10px;
            @include respond-to('m') {
              display: none;
            }
          }
          .dropdown-submenu{
            position: relative;
            display: flex;
            align-items: center;
            .back{
              display: none;
            }
            &:hover {
              .dropdown-menu {
                top: -10px;
                display: block;
                animation: .4s fadeIn;
                margin-top: 8px;
                @include respond-to('m') {
                  display: none;
                }
              }
            }
          }
        }
      }
      &.is-active{
        .main-link {
          &:after{
            content: '';
            width: 100%;
            height: 2px;
            background-color: var(--primaryColor);
            position: absolute;
            bottom: -5px;
            left: 0;
            @include respond-to('m') {
              display: none;
            }
          }
        }
      }

      .main-arrow{
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transform: rotate(270deg);
        margin-bottom: 4px;

        @include respond-to('m') {
          width: 20%;
          transform: rotate(0deg);
        }

        &.arrowGlobal{
          display: none;
          @include respond-to('m') {
            display: flex;
          }
        }

      }

      .item-submenu{
        top: 70%;
        left: 0;
      }

      .item-submenu-next{
        left: 100%;
        top: 0;
      }
    }

    .item-submenu, .dropdown-menu {
      position: absolute;
      top: 70%;
      left: 0;
      display: none;
      width: fit-content;
      height: fit-content;
      background-color: var(--primaryColor2);
      border: 1px solid var(--primaryColor);
      z-index: 111111;
      a {
        color: var(--superColorBg2);
        white-space: nowrap;
        text-transform: capitalize;
        @include respond-to('m') {
          white-space: unset;
          width: 100%;
        }
        .arrow-down-mobile {
          margin-left: 20px;
          transform: rotate(-90deg);
          @include respond-to('m') {
            margin-left: 0;
            transform: rotate(0);
          }
        }
        &:hover{
          border-bottom: 1px solid var(--superColorBg2);
        }
      }

      li{
        margin-bottom: 0;
        padding: 10px 20px 10px 20px;
      }

      .dropdown-menu {
        top: 0;
        left: 100%;
        @include respond-to('m') {
          left: 0;
        }
      }

    }
    // MENU MOBILE VERSION
    &--rwd {
      @include respond-to('m') {
        position: relative;
        top: 0;
      }
      .arrow-mobile {
        @include respond-to('m') {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        }
      }
      .arrow-down-mobile, .arrow-left-mobile {
        fill: var(--superColorBg2);
        stroke: var(--superColorBg2);
      }
      .arrow-down-mobile {
        @include respond-to('m') {
          margin-right: 10px;
        }
      }
      .menu-item {
        @include respond-to('m') {
          position: unset;
          margin: 0;
        }
      }
      .open-sub {
        @include respond-to('m') {
          background-color: var(--primaryColor2);
          display: block !important;
          top: 0 !important;
          height: 100vh;
          width: 100%;
        }
      }
      .item-submenu {
        li {
          @include respond-to('m') {
            padding: 0;
            margin-bottom: 0;
            height: 50px;
            display: flex;
          }
        }
        a {
          text-transform: uppercase;
          padding-right: 20px;
          min-height: 16px;
          &:hover {
            @include respond-to('m') {
              border-bottom: none;
            }
          }
        }
      }
      .dropdown-submenu {
        @include respond-to('m') {
          width: 100%;
        }
        a {
          @include respond-to('m') {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      .back {
        display: none;
        @include respond-to('m') {
          display: block;
        }
        a {
          @include respond-to('m') {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
        .arrow-left-mobile {
          @include respond-to('m') {
            margin-right: 21px;
          }
        }
      }
    }
  }
}

  /* Defines the animation keyframes */
  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
    }

    72% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    0% {
      opacity: 0;
    }

    72% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes fadein {
    0% {
      opacity: 0;
    }

    72% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }

  }


  /* Defines the animation keyframes */
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    72% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    72% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    72% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }

  }

